<div class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="alert alert-danger" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
    <div class="row">
      <div class="form-group col-md-12 col-sm-12 ">
        <div>
          <label class="mt-2"><strong>Enable Procore Integration</strong></label>
          <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [formControl]="procoreEnableFormControl"
            (switchChanged)="onProcoreEnabledChange($event)">
          </obc-switch>
        </div>
        <div class="mt-2">
          <button class="btn btn-danger ml-1" (click)="onSaveProcoreEnable()"
            *ngIf="currentProcoreEnabledState == true && isProcoreEnable == false">
            <i class="fa fa-save"></i> Disable Procore
          </button>
          <button class="btn btn-primary ml-1" (click)="checkPermissions(showPermissions)"
            *ngIf="isGodUser && currentProcoreEnabledState && isProcoreEnable && procoreCheckTokenResponse?.configuration?.procoreCompanyId">
            Check Permissions
          </button>
          <ng-container *ngIf="isProcoreEnable && !editClientInfo">
            <button class="btn btn-danger ml-1" (click)="enableEdit()"
              [disabled]="(isInEditingMode && (!configFormGroup.valid || (!configFormGroup.dirty && currentProcoreEnabledState == true)))">
              <i class="fa fa-{{isInEditingMode? 'save':'edit'}}"></i>
              {{(isInEditingMode? "Save Changes": "Edit Configuration")}}
            </button>
            <button class="btn btn-primary ml-1" (click)="cancelEdit()" *ngIf="showConfig && isInEditingMode">
              <i class="fa fa-undo"></i> Cancel
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isProcoreEnable">
      <div class="card mt-4">
        <div class="card-header">Authentication</div>
        <div class="card-body">
          <div class="row" *ngIf="!editClientInfo">
            <div class="col-md-8 col-sm-8">
              <div>
                <label>API User Email:</label>
                <strong class="ml-1">{{apiUserEmail}}</strong>
              </div>
              <div *ngIf="procoreCheckTokenResponse?.configuration?.procoreCompanyId">
                <label>Company:</label>
                <strong class="ml-1">{{procoreCompanyInfo}}</strong>
              </div>
              <div>
                <span
                  *ngIf="procoreCheckTokenResponse?.configuration?.procoreConnectionMode == ProcoreConnectionMode.ServiceAccount">
                  <label>
                    <div class="text-info mr-3">Service Account</div>
                    Client Id:
                  </label>
                  <strong class="ml-1">{{procoreCheckTokenResponse?.configuration?.clientId}}</strong>
                </span>
                <span
                  *ngIf="procoreCheckTokenResponse?.configuration?.procoreConnectionMode == ProcoreConnectionMode.MarketplaceApp">
                  <label class="text-info">Marketplace (Legacy)</label>
                </span>
                <span
                  *ngIf="procoreCheckTokenResponse?.configuration?.procoreConnectionMode == ProcoreConnectionMode.DMSA">
                  <label class="text-info">Marketplace (DMSA)</label>
                </span>
                <button class="btn btn-{{disableEdit? 'dark':'primary'}} btn-sm ml-2" (click)="editClientInfo = true"
                  [disabled]="disableEdit">Edit</button>
              </div>
              <div class="alert alert-info" *ngIf="infoMessage">
                <small>{{infoMessage}}</small>
              </div>
            </div>
          </div>
          <div class="row" [formGroup]="secretFormGroup" *ngIf="editClientInfo">

            <div class="col-md-12">
              <label>Integration Mode</label>
              <div>
                <label>
                  <input type="radio" class="radio" formControlName="procoreConnectionMode"
                    [value]="ProcoreConnectionMode.DMSA">
                  Marketplace (DMSA)
                </label>
                <label class="ml-3">
                  <input type="radio" class="radio" formControlName="procoreConnectionMode"
                    [value]="ProcoreConnectionMode.MarketplaceApp">
                  Marketplace (Legacy)
                </label>
              </div>
            </div>

            <ng-container
              *ngIf="secretFormGroup.controls.procoreConnectionMode.value == ProcoreConnectionMode.ServiceAccount">
              <div class="form-group col-md-12 col-sm-12">
                <label>Client Id</label>
                <input type="text" class="form-control" formControlName="clientId">
              </div>
              <div class="form-group col-md-12 col-sm-12">
                <label>Client Secret</label>
                <input type="text" class="form-control" formControlName="clientSecret">
              </div>
            </ng-container>

            <div class="form-group col-md-12 col-sm-12">
              <button class="btn btn-success"
                *ngIf="secretFormGroup.controls.procoreConnectionMode.value == ProcoreConnectionMode.ServiceAccount"
                [disabled]="!secretFormGroup.valid" (click)="onCheckAndSaveAuthorization()">
                Check & Save Token
              </button>
              <button class="btn btn-success"
                *ngIf="secretFormGroup.controls.procoreConnectionMode.value == ProcoreConnectionMode.MarketplaceApp || secretFormGroup.controls.procoreConnectionMode.value == ProcoreConnectionMode.DMSA"
                (click)=" onAuthOnProcore()">
                Authorize On Procore
              </button>

            </div>

            <div class="col-md-12">
              <hr>
            </div>

          </div>
        </div>
      </div>

      <div [formGroup]="configFormGroup" *ngIf="editModeOfEnabledZone">



        <div class="card mt-3"
          *ngIf="procoreCheckTokenResponse?.procoreSummeryInfo?.me && procoreCheckTokenResponse?.procoreSummeryInfo?.companies">
          <div class="card-header">
            <strong>Procore Company</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-md-12 col-sm-12 d-flex flex-column">
                <label>Procore Company</label>
                <select type="text" class="form-control" formControlName="procoreCompanyId"
                  [attr.disabled]="disableEdit? '' : null">
                  <option *ngFor="let c of companyList" value="{{c.id}}">
                    {{c.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="configFormGroup.controls.procoreCompanyId.value">
          <div class="card mt-3">
            <div class="card-header">
              <strong>Procore Webhooks</strong>
            </div>
            <div class="card-body">

              <div class="row mt-3" *ngIf="showConfig">
                <div class="form-group col-md-3 col-sm-12 d-flex flex-column">
                  <label>
                    Enable Procore Webhooks
                  </label>
                  <obc-switch [trueLabel]="' Enable '" [falseLabel]="' Disable '" [disabled]="disableEdit"
                    formControlName="procoreWebHookEnabled">
                  </obc-switch>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3">
            <div class="card-header">
              <strong>Import Sites</strong>
            </div>
            <div class="card-body">

              <div class="row">
                <div class="form-group col-md-3 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Import Sites</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '"
                    formControlName="procoreImportSitesEnabled" [disabled]="disableEdit">
                  </obc-switch>
                </div>
                <div *ngIf="configFormGroup.get('procoreImportSitesEnabled').value"
                  class="form-group col-md-9 col-sm-12 d-flex flex-column">
                  <label>Site Template</label>
                  <select formControlName="procoreTemplateSiteId" class="form-control"
                    [attr.disabled]="disableEdit? true : null">
                    <option [ngValue]="null"> --- Select Site Import Template ---</option>
                    <option *ngFor="let template of templateSites" [ngValue]="template.id">{{ template.name }}</option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportSite]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportSite].lastInvokeDateTime | datetime}}
                    </span>
                    <a tooltip="Sync As Soon As Possible"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.ImportSite)"
                      *ngIf="configFormGroup.get('procoreImportSitesEnabled').value" class="btn btn-sm btn-warning">
                      <i class="fas fa-redo"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="form-group col-md-6 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Import Site Employees</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreImportUsersEnabled">
                  </obc-switch>
                </div>
                <div class="form-group col-md-6 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Import Site Users as Frequent User</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreImportSiteUserAsFrequent">
                  </obc-switch>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportUser]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportUser].lastInvokeDateTime | datetime}}
                    </span>
                    <a tooltip="Sync As Soon As Possible"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.ImportUser)"
                      *ngIf="configFormGroup.get('procoreImportUsersEnabled').value" class="btn btn-sm btn-warning">
                      <i class="fas fa-redo"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <strong>Import Directories</strong>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-md-6 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Import Company Directory</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreImportCompanyDirectoryEnabled"></obc-switch>
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportCompanyDirectory]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{
                      procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportCompanyDirectory].lastInvokeDateTime
                      |
                      datetime
                      }}
                    </span>
                    <a tooltip="Sync As Soon As Possible" *ngIf="showReSyncButtonForImportCompanyDirectory"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.ImportCompanyDirectory)"
                      class="btn btn-sm btn-warning"><i class="fas fa-redo"></i></a>
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Import Project Directory</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreImportProjectDirectoryEnabled"></obc-switch>
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportProjectDirectory]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{
                      procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportProjectDirectory].lastInvokeDateTime
                      |
                      datetime
                      }}
                    </span>
                    <a tooltip="Sync As Soon As Possible" *ngIf="showReSyncButtonForImportProjectDirectory"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.ImportProjectDirectory)"
                      class="btn btn-sm btn-warning"><i class="fas fa-redo"></i></a>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-12">

                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <strong>Import Roles</strong>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-md-6 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Import Company Roles</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreImportCompanyRolesEnabled"></obc-switch>
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportCompanyRoles]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{
                      procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportCompanyRoles].lastInvokeDateTime
                      |
                      datetime
                      }}
                    </span>
                    <a tooltip="Sync As Soon As Possible" *ngIf="showReSyncButtonForImportCompanyRoles"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.ImportCompanyRoles)"
                      class="btn btn-sm btn-warning"><i class="fas fa-redo"></i></a>
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Import Project User Roles</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreImportProjectUserRolesEnabled"></obc-switch>
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportProjectUserRoles]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{
                      procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ImportProjectUserRoles].lastInvokeDateTime
                      |
                      datetime
                      }}
                    </span>
                    <a tooltip="Sync As Soon As Possible" *ngIf="showReSyncButtonForImportProjectUserRoles"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.ImportProjectUserRoles)"
                      class="btn btn-sm btn-warning"><i class="fas fa-redo"></i></a>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-12">

                </div>
              </div>
            </div>
          </div>


          <div class="card">
            <div class="card-header">
              <strong>Export Checkins As</strong>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-md-4 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Timecard (Employees Only)</strong>
                  </label>
                  <select required class="form-control" [attr.disabled]="disableEdit? '' : null"
                    [formControl]="configFormGroup.get('procoreExportSiteAttendanceAsTimeCardMode')">
                    <option *ngFor="let item of ProcoreExportSiteAttendanceAsTimeCardMode| enumToArray "
                      [ngValue]="ProcoreExportSiteAttendanceAsTimeCardMode[item]">
                      {{ProcoreExportSiteAttendanceAsTimeCardMode[item] |
                      procoreExportSiteAttendanceAsTimeCardModePipe}}
                    </option>
                  </select>
                  <div class="mt-3 d-flex align-items-center"
                    *ngIf="configFormGroup.get('procoreExportSiteAttendanceAsTimeCardMode')?.value != ProcoreExportSiteAttendanceAsTimeCardMode.Disabled">
                    <label class="mr-2">
                      <strong>Sync Incomplete Timecard </strong>
                    </label>
                    <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                      formControlName="enableProcoreSyncIncompleteForTimecard"></obc-switch>
                  </div>
                  <div class="mt-4"
                    *ngIf="configFormGroup.get('procoreExportSiteAttendanceAsTimeCardMode')?.value != ProcoreExportSiteAttendanceAsTimeCardMode.Disabled">
                    <div class="row">
                      <div class="col-md-4">
                        <label>
                          <strong>Timecard Description</strong>
                        </label>
                      </div>
                      <div class="col-md-8">
                        <select class="form-control" formControlName="procoreCommentModeForTimecards"
                          [attr.disabled]="disableEdit? '' : null">
                          <option *ngFor="let item of ProcoreCommentMode | enumToArray"
                            [ngValue]="ProcoreCommentMode[item]">
                            {{ProcoreCommentMode[item] | procoreCommentModePipe}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-4 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Visitor Log</strong>
                  </label>
                  <select required class="form-control" [attr.disabled]="disableEdit? '' : null"
                    [formControl]="configFormGroup.get('procoreExportSiteAttendanceAsVisitorLogMode')">
                    <option *ngFor="let item of ProcoreExportSiteAttendanceAsVisitorLogMode | enumToArray "
                      [ngValue]="ProcoreExportSiteAttendanceAsVisitorLogMode[item]">
                      {{ProcoreExportSiteAttendanceAsVisitorLogMode[item] |
                      procoreExportSiteAttendanceAsVisitorLogModePipe}}
                    </option>
                  </select>
                  <div class="mt-3 d-flex align-items-center"
                    *ngIf="configFormGroup.get('procoreExportSiteAttendanceAsVisitorLogMode')?.value != ProcoreExportSiteAttendanceAsVisitorLogMode.Disabled">
                    <label class="mr-2">
                      <strong>Sync Incomplete Visitor Log </strong>
                    </label>
                    <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                      formControlName="enableProcoreSyncIncompleteForVisitorLog"></obc-switch>
                  </div>
                  <div class="mt-3"
                    *ngIf="configFormGroup.get('procoreExportSiteAttendanceAsVisitorLogMode')?.value != ProcoreExportSiteAttendanceAsVisitorLogMode.Disabled">
                    <div class="row">
                      <div class="col-md-4">
                        <label>
                          <strong>Visitor Log Description</strong>
                        </label>
                      </div>
                      <div class="col-md-8">
                        <select class="form-control" formControlName="procoreCommentModeForVisitorLogs"
                          [attr.disabled]="disableEdit? '' : null">
                          <option *ngFor="let item of ProcoreCommentMode | enumToArray"
                            [ngValue]="ProcoreCommentMode[item]">
                            {{ProcoreCommentMode[item] | procoreCommentModePipe}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-4 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Manpower Log</strong>
                  </label>
                  <select required class="form-control" [attr.disabled]="disableEdit? '' : null"
                    [formControl]="configFormGroup.get('procoreExportSiteAttendanceAsManpowerMode')">
                    <option *ngFor="let item of ProcoreExportSiteAttendanceAsManpowerMode | enumToArray "
                      [ngValue]="ProcoreExportSiteAttendanceAsManpowerMode[item]">
                      {{ProcoreExportSiteAttendanceAsManpowerMode[item] |
                      procoreExportSiteAttendanceAsManpowerModePipe}}
                    </option>
                  </select>
                  <div class="mt-3 d-flex align-items-center"
                    *ngIf="configFormGroup.get('procoreExportSiteAttendanceAsManpowerMode')?.value != ProcoreExportSiteAttendanceAsManpowerMode.Disabled">
                    <label class="mr-2">
                      <strong>Sync Incomplete Manpower </strong>
                    </label>
                    <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                      formControlName="enableProcoreSyncIncompleteForManpower"></obc-switch>
                  </div>
                  <div class="mt-3"
                    *ngIf="configFormGroup.get('procoreExportSiteAttendanceAsManpowerMode')?.value != ProcoreExportSiteAttendanceAsManpowerMode.Disabled">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="mr-2">
                          <strong>Manpower Description</strong>
                        </label>
                      </div>
                      <div class="col-md-8">
                        <select class="form-control" formControlName="procoreCommentModeForManpowers"
                          [attr.disabled]="disableEdit? '' : null">
                          <option *ngFor="let item of ProcoreCommentMode | enumToArray"
                            [ngValue]="ProcoreCommentMode[item]">
                            {{ProcoreCommentMode[item] | procoreCommentModePipe}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ExportSiteAttendance]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{
                      procoreIntegrationTypeInfos[ProcoreIntegrationTypes.ExportSiteAttendance].lastInvokeDateTime |
                      datetime
                      }}
                    </span>
                    <a tooltip="Sync As Soon As Possible" *ngIf="showReSyncButtonForExportAttendance"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.ExportSiteAttendance)"
                      class="btn btn-sm btn-warning"><i class="fas fa-redo"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <strong>Export Documents</strong>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-md-4 col-sm-12">
                  <label>
                    <strong>Export Folder Template</strong></label>
                  <select class="custom-select form-control" [attr.disabled]="disableEdit? '' : null"
                    [formControl]="configFormGroup.get('procoreFolderStructureMode')">
                    <option *ngFor="let index of ProcoreFolderStructureMode | enumToArray"
                      [ngValue]="ProcoreFolderStructureMode[index]">
                      {{ProcoreFolderStructureMode[index] | procoreFolderStructureMode }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-3 col-sm-12">
                  <label class="mr-2">
                    <strong>Create New Procore Folders as</strong></label>
                  <div>
                    <select class="custom-select form-control" [attr.disabled]="disableEdit? '' : null"
                      [formControl]="configFormGroup.get('isCreateProcoreFolderAsPrivate')">
                      <option [value]="false">
                        Public
                      </option>
                      <option [value]="true">
                        Private
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="form-group col-md-4 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Export Supplier Documents</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreUploadSiteSupplierDocumentsEnabled"></obc-switch>
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.UploadSiteSupplierDocumentsEnabled]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{
                      procoreIntegrationTypeInfos[ProcoreIntegrationTypes.UploadSiteSupplierDocumentsEnabled].lastInvokeDateTime
                      |
                      datetime
                      }}
                    </span>
                    <a tooltip="Sync As Soon As Possible" *ngIf="showReSyncButtonForUploadSiteSupplierDocuments"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.UploadSiteSupplierDocumentsEnabled)"
                      class="btn btn-sm btn-warning"><i class="fas fa-redo"></i></a>
                  </div>
                </div>
                <div class="form-group col-md-4 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Export User Inductions</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreUploadSiteInductionsEnabled">
                  </obc-switch>
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.UploadSiteInductionsEnabled]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{
                      procoreIntegrationTypeInfos[ProcoreIntegrationTypes.UploadSiteInductionsEnabled].lastInvokeDateTime
                      |
                      datetime
                      }}
                    </span>
                    <a tooltip="Sync As Soon As Possible" *ngIf="showReSyncButtonForUploadSiteInductions"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.UploadSiteInductionsEnabled)"
                      class="btn btn-sm btn-warning"><i class="fas fa-redo"></i></a>
                  </div>
                </div>
                <div class="form-group col-md-4 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Export Permits</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreUploadPermitFormsEnabled">
                  </obc-switch>
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.UploadPermitFormsEnabled]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{
                      procoreIntegrationTypeInfos[ProcoreIntegrationTypes.UploadPermitFormsEnabled].lastInvokeDateTime
                      |
                      datetime
                      }}
                    </span>
                    <a tooltip="Sync As Soon As Possible" *ngIf="showReSyncButtonForUploadPermitForms"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.UploadPermitFormsEnabled)"
                      class="btn btn-sm btn-warning"><i class="fas fa-redo"></i></a>
                  </div>
                </div>
              </div>
              <div class="row">

                <div class="form-group col-md-4 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Export Site Assets</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreUploadSiteAssetsFormEnabled">
                  </obc-switch>
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.UploadSiteAssetFormsEnabled]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{
                      procoreIntegrationTypeInfos[ProcoreIntegrationTypes.UploadSiteAssetFormsEnabled].lastInvokeDateTime
                      |
                      datetime
                      }}
                    </span>
                    <a tooltip="Sync As Soon As Possible" *ngIf="showReSyncButtonForUploadSiteAssetForms"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.UploadSiteAssetFormsEnabled)"
                      class="btn btn-sm btn-warning"><i class="fas fa-redo"></i></a>
                  </div>
                </div>
                <div class="form-group col-md-4 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Export Site Briefings</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreUploadSiteBriefingsEnabled">
                  </obc-switch>
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.UploadSiteBriefingsEnabled]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{
                      procoreIntegrationTypeInfos[ProcoreIntegrationTypes.UploadSiteBriefingsEnabled].lastInvokeDateTime
                      |
                      datetime
                      }}
                    </span>
                    <a tooltip="Sync As Soon As Possible" *ngIf="showReSyncButtonForUploadSiteBriefings"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.UploadSiteBriefingsEnabled)"
                      class="btn btn-sm btn-warning"><i class="fas fa-redo"></i></a>
                  </div>
                </div>
                <div class="form-group col-md-4 col-sm-12 d-flex flex-column">
                  <label>
                    <strong>Export Generic Forms</strong>
                  </label>
                  <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [disabled]="disableEdit"
                    formControlName="procoreUploadGenericFormsEnabled">
                  </obc-switch>
                  <div class="sync-info alert alert-info"
                    *ngIf="procoreIntegrationTypeInfos && procoreIntegrationTypeInfos[ProcoreIntegrationTypes.procoreUploadGenericFormsEnabled]?.lastInvokeDateTime">
                    Last Sync:
                    <span class="sync-date">
                      {{
                      procoreIntegrationTypeInfos[ProcoreIntegrationTypes.procoreUploadGenericFormsEnabled].lastInvokeDateTime
                      |
                      datetime
                      }}
                    </span>
                    <a tooltip="Sync As Soon As Possible" *ngIf="showReSyncButtonForUploadGenericForms"
                      (click)="resetProcoreSchedule(ProcoreIntegrationTypes.procoreUploadGenericFormsEnabled)"
                      class="btn btn-sm btn-warning"><i class="fas fa-redo"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- <div class="mt-4">
        <accordion>
          <accordion-group heading="Help & Instructions">
            <div class="form-group col-md-12 col-sm-12 text-right">
              <div class="d-flex flex-column">
                <label class="text-left bold">
                  1. To Import Site's you MUST add {{apiUserEmail}} for EACH Project.
                </label>
                <label class="text-left bold">
                  2. To Import employees please provide the directory standard Permission FOR API USER to Each Project;
                  ALSO User MUST Be Employee, Active AND have a Phone Number.
                </label>
                <label class="text-left bold">
                  3. To export Timecards of Employee's please provide the daily log AND Timesheet Admin permissions To
                  API USER For Each Project.
                </label>
                <label class="text-left bold">4. Your 1Breadcrumb Company MUST have Admin OR Owner User permissions in
                  procore.</label>
              </div>
            </div>
          </accordion-group>
        </accordion>
      </div> -->
    </ng-container>
  </div>
</div>

<ng-template #showPermissions>
  <div class="card m-1">
    <div class="card-header">
      <span>
        Permissions on Procore
      </span>
      <button type="button" class="close" (click)="bsModalHandle.hide();">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row" *ngIf="this.companyPermissionsProjects?.companyPermissions">
        <div class="col-md-12">
          <strong>Company Permissions</strong>
          <ng-container
            *ngTemplateOutlet="procorePermission, context: {permissions: companyPermissionsProjects?.companyPermissions}"></ng-container>
        </div>
      </div>

      <strong>Projects</strong>
      <div class="mt-3">
        <accordion closeOthers="true">
          <accordion-group *ngFor="let item of this.companyPermissionsProjects?.companyProjects"
            (isOpenChange)="getProjectPermission(item)">
            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
              <div class="pull-left">
                <i class="fa-archway fas mr-2 text-success"></i>
                {{item.name}}
                <i *ngIf="item.isMappedToObcSite" class="fas fa-link mr-2 ml-2 text-success"></i>
              </div>
            </button>
            <div>
              <div>
                Address:
                <strong>{{item.procoreAddress}}</strong>
              </div>
              <div>
                Longitude/Latitude:
                <strong *ngIf="item.procoreLongitude && item.procoreLatitude">
                  {{item.procoreLongitude}}/{{item.procoreLatitude}}
                </strong>
              </div>
              <div>
                Linked To Site:
                <strong *ngIf="!item.obcSiteName">-</strong>
                <strong *ngIf="item.obcSiteName">
                  {{item.obcSiteName}}
                  <span *ngIf="item.obcSiteReference">({{item.obcSiteReference}})</span>
                </strong>
              </div>
              <ng-container
                *ngTemplateOutlet="procorePermission, context: {permissions: item.projectPermissions}"></ng-container>
            </div>
          </accordion-group>
        </accordion>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #procorePermission let-permissions="permissions">
  <div>
    <div *ngIf="!permissions">
      <obc-loader [show]="true"></obc-loader>
    </div>
    <div class="table-responsive" *ngIf="permissions?.length > 0">
      <table class="table table-bordered table-striped mt-3">
        <thead>
          <tr>
            <td>
              Name
            </td>
            <td>
              Permission
            </td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of permissions">
            <tr>
              <td>
                {{ item.friendlyName }}
              </td>
              <td>
                <span>
                  {{item.currentAccessLevel}}
                  <span *ngIf="!item.isCorrectAccess">
                    (Required: <strong>{{item.requiredAccessLevel}}</strong>)
                  </span>
                  <i
                    class="fas fa-{{item.isCorrectAccess ? 'check-circle text-success' : 'times-circle text-danger'}} mr-2"></i>
                </span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
import { Pipe, PipeTransform } from '@angular/core';
import { ProcoreCommentMode } from '../enums/procore-export-modes.enum';

@Pipe({
  name: 'procoreCommentModePipe'
})
export class ProcoreCommentModePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value == ProcoreCommentMode.Disabled) return 'Disabled';
    if (value == ProcoreCommentMode.Mobile) return 'Mobile';
    if (value == ProcoreCommentMode.FullName) return 'Full Name';
    if (value == ProcoreCommentMode.MobileFullName) return 'Mobile and Full Name';
    return value.toString();
  }

}

export enum WorkerProfileField {
  FirstName = 0,
  LastName = 1,
  Mobile = 2,
  VisitorType = 3,
  Supplier = 4,
  Email = 5,
  Gender = 6,
  DOB = 7,
  EmergencyContact= 8,
}

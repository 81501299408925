import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentExpiryStatus } from '../../enums/document-expiry-status.enum';
import { FormBuilder } from '@angular/forms';
import { LayoutService } from '../../services/layout.service';
import { AttendanceDocumentReportViewModel } from 'src/app/models/attendance-document-report-viewmodel';
import { UserService } from 'src/app/services/user.service';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { GridColumn, GridConfig, InitialFilter } from "../../models/grid/grid.config";
import { Table } from "../../enums/table.enum";
import { EnumToArrayPipe } from "../../pipes/enum-to-array.pipe";

@Component({
  selector: 'obc-document-report',
  templateUrl: './document-report.component.html',
  styleUrls: ['./document-report.component.scss']
})

export class DocumentReportComponent implements OnInit {
  showAllCards: boolean = true;
  @ViewChild('grid') grid: any;

  initialFilters: InitialFilter[] = [
    {
      key: 'documentExpiryStatus',
      value: this.enumToArrayPipe.transform(DocumentExpiryStatus),
      displayInFilterRenderer: true,
    },
    {
      key: 'checkInDate',
      value: null,
      displayInFilterRenderer: true,
    },
    {
      key: 'showAllCards',
      value: this.showAllCards,
      displayInFilterRenderer: true,
    }
  ]

  constructor(
    private formBuilder: FormBuilder,
    private layoutService: LayoutService,
    public userService: UserService,
    private enumToArrayPipe: EnumToArrayPipe,
  ) { }

  async ngOnInit() {
    this.layoutService.header = 'Card Report';
    this.prepareGridConfig();
  }
  gridColumns: GridColumn<AttendanceDocumentReportViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;
  prepareGridConfig() {
    let currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 1);
    let filterCheckInDateRanges = { from: currentDate, to: null };
    this.initialFilters.find(x => x.key == "checkInDate").value = filterCheckInDateRanges;

    this.gridConfig = new GridConfig({
      apiUrl: '/api/SiteAttendance/attendance-document-report',
      tableType: Table.CardReport,
      displayGeneralSearch: true,
      generalSearchPlaceholder: "Filter Result by Mobile, First Name, Last Name, Site",
      initialFilters: this.initialFilters,
      csvReportUrl: '/api/SiteAttendance/export-attendance-document-report-csv-as-job',
    });
    this.gridColumns = [
      {
        name: "Full Name", key: "fullName",
        type: FilterType.Text, enableSort: true,
        enableFilter: false,
        propertyNameInQuery: 'names',
        visible: true,
      },
      {
        name: "Supplier", key: "supplierName",
        sortBy: "supplier",
        type: FilterType.Supplier, enableSort: true, enableFilter: true,
        propertyNameInQuery: 'suppliers',
        visible: true,
      },
      {
        name: "Mobile", key: "mobile", type: FilterType.Mobile, enableSort: true, enableFilter: true,
        propertyNameInQuery: 'userIds',
        visible: true,
      },
      {
        name: "Site", key: "siteName",
        sortBy: "site",
        type: FilterType.Site, enableSort: true, enableFilter: true,
        propertyNameInQuery: 'siteIds',
        visible: true,
      },
      {
        name: "Check In Time", key: "checkInDate", type: FilterType.DateTimeRange, enableSort: true, enableFilter: true,
        propertyNameInQuery: 'checkInDateRange',
        visible: true,
        isArray: false,
      },
      {
        name: "Type", key: "cardType",
        type: FilterType.DocumentType, enableSort: true, enableFilter: true,
        propertyNameInQuery: 'documentTypeIds',
        visible: true,
      },
      {
        name: "Title", key: "title", type: FilterType.SingleText, enableSort: true, enableFilter: true,
        propertyNameInQuery: 'titles',
        visible: false,
      },
      {
        name: "Expiry Status",
        key: "documentExpiryStatus",
        sortBy: "expirayStatus",
        type: FilterType.DocumentExpiryStatus,
        enableSort: true,
        enableFilter: true,
        visible: true,
        propertyNameInQuery: 'documentExpiryStatuses',
      },
      {
        name: "Expiration Date",
        key: "expirationDate",
        type: FilterType.DateTimeRange,
        enableSort: true,
        enableFilter: true,
        visible: true,
        propertyNameInQuery: 'expirationDateRanges',
        isArray: false,
      },
      {
        name: "File", key: "documentLinks", type: FilterType.File, enableSort: false, enableFilter: false,
        displayFunction: (_) => { return 'View Documents' },
        visible: true,
      },
    ];
  }

  onActiveChanged(value) {
    this.showAllCards = value;
    this.initialFilters.find(x => x.key == "showAllCards").value = this.showAllCards;
    this.grid.initialFilters = this.initialFilters;
    this.grid.loadData(0);
  }
}



<div class="row">
    <div class="col-md-12">
        <div class="card">
            <obc-loader [show]="inProgress"></obc-loader>
            <header class="card-header">
                <div class="d-flex flex-row justify-content-between">
                    <div>Evacuation Report</div>
                </div>
            </header>
            <div class="card-body">
                <div class="form-group col-md-12 col-sm-12">
                    <label>Select Site</label>
                    <app-site-selector #sl [activeStatus]="SiteActiveStatus.Active" required="true"
                        (siteIdSelect)="onChangeSelectedSite($event)">
                    </app-site-selector>
                </div>
                <div class="row" *ngIf="result">
                    <div class="col-md-12 mt-2">
                        <h5 class="pull-left">
                            <span>
                                {{result.evacuationViewModels?.length}} Records
                            </span>
                        </h5>
                        <div class=" pull-right d-flex justify-content-end">
                            <obc-export-csv-popup (onExport)="exportCsv($event)"
                                                  [userInfo]="userInfo"
                                                  [title]="'Evacuation Report'"
                                                  [displayColumnFilterRadios]="false"
                                                  [disabled]="!result || !result.evacuationViewModels.length"></obc-export-csv-popup>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped mt-3">
                                <thead>
                                    <tr>
                                        <td>Mode</td>
                                        <td>Created By</td>
                                        <td>Created At</td>
                                        <td>Total People Count</td>
                                        <td>Confirmed Count</td>
                                        <td>Status</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="result">
                                    <ng-container *ngFor="let item of result.evacuationViewModels">
                                        <tr>
                                            <td>{{item.mode | evacuationMode}}</td>
                                            <td>{{item.createByUserFullName}}</td>
                                            <td>{{item.createDateTime | datetime}}</td>
                                            <td>{{item.totalPeople}}</td>
                                            <td>{{item.totalPresentPeople}}</td>
                                            <td>{{item.status | evacuationStatus}}</td>
                                            <td>
                                                <obc-dropdown-button>
                                                  <button dropdown-button class="btn btn-info btn-sm" (click)="openEvcuationModal(item, evacuationDetailsTemplate)">Details</button>
                                                  <ul id="dropdown-split" dropdown-items class="dropdown-menu" role="menu" aria-labelledby="button-split">
                                                    <li role="menuitem">
                                                      <a id="button-basic" class="dropdown-item cursor-pointer d-flex align-items-center"
                                                         (click)="exportPdf(item.evacuationId)">
                                                        <i class="fa fa-file-pdf mr-2"></i> Download PDF
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </obc-dropdown-button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="result && !result.evacuationViewModels?.length">
                                    <tr>
                                        <td [attr.colspan]="10">
                                            <div class="alert alert-warning">
                                                No records found.
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #evacuationDetailsTemplate>
    <obc-evacuation-details [evacuationId]="evacuationId"></obc-evacuation-details>
</ng-template>

import { FormArray, FormGroup, Validators } from '@angular/forms';
import { QuestionType } from '../enums/question-type';
import { OptionalMandatoryState } from '../enums/site-document-state.enum';
import { SiteAttendanceQuestionAnswer } from '../models/attendance-request/site-attendance-question-answer';
import { SiteQuestionViewModel } from '../models/site-question-viewmodel';
import { CustomFormControl } from "../models/custom-formcontrol";
import { RequiredDocumentAnswer } from "../models/requiredDocumentAnswer";
import { RequiredDocumentViewModel } from "../models/user-document-type";
import { SupplierCheckinViewModel } from "../models/supplier-checkin-view-model";
import { FileType } from "../enums/file-type.enum";
import { CheckListItemViewModel } from '../custom-controls/check-list-form-control/check-list-item';

export const getPaginationKeyByUrl = () => {
  let url = window.location.pathname.replace(/\d+/g, '');
  url = url.replace(/^\//, '');
  url = url.replace(/(\/)+/g, '\/');
  url = url.replace(/\/+$/g, '');
  return "items_per_page_" + url;
};
export const getPageSizeByUrl = () => {
  let paginationKey = getPaginationKeyByUrl();

  if (localStorage && localStorage.getItem(paginationKey)) {
    return parseInt(localStorage.getItem(paginationKey), 10);
  }
  return null;
};

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const removeContentTypeFromBase64 = (body: string) => {
  if (body == null) return null;
  const parts = (body as string).split(';base64,');
  if (parts.length != 2)
    return body;
  return parts[1];
};
export function getBase64FileType(source) {
  return fileTypes[source.substring("data:image/".length, source.indexOf(";base64"))];
}

export function validateEmail(email: string) {
  const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return email != null && regexp.test(email);
}

export function popup(url: string, title: string, width: number = null, height: number = null): Window {
  if (!width)
    width = screen.width / 2;
  if (!height)
    height = screen.height / 2;

  const left = (screen.width - width) / 2;
  const top = (screen.height - height) / 2;
  return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);
}

export function isEquall(obj1: Object, obj2: Object): boolean {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}


export function compare(obj1, obj2) {
  //Loop through properties in object 1
  for (const p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof (obj1[p])) {
      //Deep compare objects
      case 'object':
        if (!compare(obj1[p], obj2[p])) return false;
        break;
      //Compare function code
      case 'function':
        if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) return false;
        break;
      //Compare values
      default:
        if (obj1[p] != obj2[p]) return false;
    }
  }

  //Check object 2 for any extra properties
  for (const p in obj2) {
    if (typeof (obj1[p]) == 'undefined') return false;
  }
  return true;
}

export function getFileExtenstion(filename: string) {
  const re = /(?:\.([^.]+))?$/;
  return re.exec(filename)[1];
}

export function getFilename(filename: string) {
  return filename.match(/([^\/]+)(?=\.\w+$)/)[0];
}

export function formGroupToModel(formGroup: FormGroup) {
  const keys = Object.keys(formGroup?.controls);
  const ret = {};
  for (let index = 0; index < keys.length; index++) {
    const element = keys[index];
    ret[element] = formGroup.controls[element].value;
  }
  return ret;
}

export function deepCloneJson(input: any): any {
  if (input == null) return null;
  return JSON.parse(JSON.stringify(input));
}


export function maxNumber(list: number[]) {
  return Math.max(...list);
}

export function minNumber(list) {
  return Math.min(...list);
}

export function clone<T>(obj: T): T {
  const keys = Object.keys(obj);
  const ret = {} as T;
  for (let index = 0; index < keys.length; index++) {
    const element = keys[index];
    ret[element] = obj[element];
  }
  return ret;
}

export function getEndPointUrl() {
  return `${location.protocol}//${location.hostname}${location.port?.length ? `:${location.port}` : ''}`;
}

export function copyToClipboard(inputId: string) {
  const nativeElement = document.getElementById(inputId) as any;
  nativeElement.select();
  nativeElement.setSelectionRange(0, 99999);
  document.execCommand("copy");
}

export function getCountLabel(count: number, label: string, useExactLabel = false) {
  if (count == 0) return '';
  let lbl = label ?? '';
  return `${count} ${lbl}${useExactLabel == true || count == 1 ? '' : 's'}`;
}


export function isEqual(x, y) {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (const p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof (x[p]) !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!isEqual(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (const p in y)
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p))
      return false;
  // allows x[ p ] to be set to undefined

  return true;
}

export function deepCloneCircular(obj, hash = new WeakMap()) {
  // Do not try to clone primitives or functions
  let result;
  if (Object(obj) !== obj || obj instanceof Function) return obj;
  if (obj instanceof Date) return new Date(obj.getTime());
  if (hash.has(obj)) return hash.get(obj); // Cyclic reference
  try { // Try to run constructor (without arguments, as we don't know them)
    result = new obj.constructor();
  } catch (e) { // Constructor failed, create object without running the constructor
    result = Object.create(Object.getPrototypeOf(obj));
  }
  // Optional: support for some standard constructors (extend as desired)
  if (obj instanceof Map)
    Array.from(obj, ([key, val]) => result.set(deepCloneCircular(key, hash),
      deepCloneCircular(val, hash)));
  else if (obj instanceof Set)
    Array.from(obj, (key) => result.add(deepCloneCircular(key, hash)));
  // Register in hash
  hash.set(obj, result);
  // Clone and assign enumerable own properties recursively
  return Object.assign(result, ...Object.keys(obj).map(
    key => ({ [key]: deepCloneCircular(obj[key], hash) })));
}
export function deepClone(obj) {
  if (typeof obj == 'function') {
    return obj;
  }
  const result = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    // include prototype properties
    const value = obj[key];
    const type = {}.toString.call(value).slice(8, -1);
    if (type == 'Array' || type == 'Object') {
      result[key] = deepClone(value);
    } else if (type == 'Date') {
      result[key] = new Date(value.getTime());
    } else if (type == 'RegExp') {
      result[key] = RegExp(value.source, deepClone_getRegExpFlags(value));
    } else {
      result[key] = value;
    }
  }
  return result;
}

function deepClone_getRegExpFlags(regExp) {
  if (typeof regExp.source.flags == 'string') {
    return regExp.source.flags;
  } else {
    const flags = [];
    regExp.global && flags.push('g');
    regExp.ignoreCase && flags.push('i');
    regExp.multiline && flags.push('m');
    regExp.sticky && flags.push('y');
    regExp.unicode && flags.push('u');
    return flags.join('');
  }
}

export function isQuestionIsVisibleByCheckingConditions(question: SiteQuestionViewModel,
  questions: SiteQuestionViewModel[],
  answers: SiteAttendanceQuestionAnswer[]
): boolean {
  return _isQuestionIsVisibleByCheckingConditions(question, questions, answers);
}

function _isQuestionIsVisibleByCheckingConditions(question: SiteQuestionViewModel, questions: SiteQuestionViewModel[], answers: SiteAttendanceQuestionAnswer[]): boolean {
  // has condition ?
  if (!question.conditions?.length)
    return true;

  if (!questions?.length)
    return true;
  // master exist ?
  const masterQuestion = questions.find(q => q.questionId == question.conditions[0].masterQuestionId); // all conditions have one question master
  if (!masterQuestion) return true;

  // check answers
  const cndAns = question.conditions.map(x => x.answers);
  const masterQuestionAnswer = answers?.find(x => x.questionId == masterQuestion.questionId);

  if (!masterQuestionAnswer) return false;

  if (masterQuestion.questionType != QuestionType.MultiSelect)
    return cndAns.some(x => x == masterQuestionAnswer.answer);
  else {
    return cndAns.some(condition => condition.split(',').every(ca => masterQuestionAnswer.answer.split(',').some(ua => ua == ca)));
  }
}



export function convertSingleDocumentToFormControl(document: RequiredDocumentViewModel) {
  if (document) {
    if (document.documentType?.order) {
      document.documentType.order = document.displayOrder;
    }
    let isRequired = document?.documentType?.state == OptionalMandatoryState.Optional ? [] : [Validators.required];
    return new CustomFormControl(null, document, isRequired);
  }
  return null;
}
export function convertDocumentToFormControl(documents: RequiredDocumentViewModel[], questionDocumentsFormControls: CustomFormControl[]) {
  let requiredDocumentFormControls: CustomFormControl[];
  if ((documents && documents.length > 0) || (questionDocumentsFormControls && questionDocumentsFormControls.length > 0)) {
    if (documents) {
      // documents
      requiredDocumentFormControls = documents.map(e => {
        return convertSingleDocumentToFormControl(e)
      });
    }
    let formArrayControls: CustomFormControl[] = [];
    requiredDocumentFormControls.forEach(function (c) {
      formArrayControls.push(c);
    });
    questionDocumentsFormControls.forEach(function (c) {
      formArrayControls.push(c);
    });
    return { formArray: new FormArray(formArrayControls), controls: requiredDocumentFormControls };
  }
  return { formArray: new FormArray([]), controls: [] };
}
export function getUploadedDocumentsFromFormArray(documentsFormArray: FormArray): RequiredDocumentAnswer[] {
  return documentsFormArray.controls.map(
    (formControl) => {
      let form = formControl as CustomFormControl;
      return form.value as RequiredDocumentAnswer;
    }
  ).filter(doc => doc != null);
}

// questions type is any, means = SiteQuestionViewModel, SiteQuestionsViewModel
// todo: SiteQuestionsViewModel has no isRequired property
export function setDocumentFormControlForQuestions(questions, inductionId?: number) {
  return questions?.map(function (question) {

    let requiredDocument: RequiredDocumentViewModel = question.document;
    if (requiredDocument) {
      requiredDocument.questionId = question.questionId;
      requiredDocument.inductionId = inductionId;
      requiredDocument.documentTypes.map((doc) => {
        doc.state = question.isRequired ? OptionalMandatoryState.Mandatory : OptionalMandatoryState.Optional;
      })
      requiredDocument.documentType.state = question.isRequired ? OptionalMandatoryState.Mandatory : OptionalMandatoryState.Optional;
      requiredDocument.question = question;

      requiredDocument.displayOrder = (<any>question)?.displayOrder ?? (<any>question)?.order ?? 0;
      (<any>requiredDocument).order = requiredDocument.displayOrder;
    }

    let oldValue = question?.documentFormControl?.value;
    question.documentFormControl = convertSingleDocumentToFormControl(requiredDocument);
    question.documentFormControl?.setValue(oldValue);
    return question;
  })
}

export function isDateValid(input) {
  return input.value !== null &&
    input.value != '' &&
    input.value !== undefined &&
    input.value != 'Invalid Date';
}

export function prependZero(number: number) {
  return ('0' + number).slice(-2);
}


export function isSetSupplierCheckinData(supplierCheckinViewModel: SupplierCheckinViewModel) {
  return (supplierCheckinViewModel?.siteQuestions && supplierCheckinViewModel?.siteQuestions?.length > 0) ||
    (supplierCheckinViewModel?.inductions && supplierCheckinViewModel?.inductions?.length > 0) ||
    (supplierCheckinViewModel?.announcements && supplierCheckinViewModel?.announcements?.length > 0);
}

let fileTypes = {
  'text/html': FileType.Text,
  'text/css': FileType.Text,
  'text/xml': FileType.Text,
  'image/gif': FileType.Image,
  'image/jpeg': FileType.Image,
  'text/mathml': FileType.Text,
  'text/plain': FileType.Text,
  'text/vnd.sun.j2me.app-descriptor': FileType.Text,
  'text/vnd.wap.wml': FileType.Text,
  'text/x-component': FileType.Text,
  'image/png': FileType.Image,
  'image/tiff': FileType.Image,
  'image/vnd.wap.wbmp': FileType.Image,
  'image/x-icon': FileType.Image,
  'image/x-jng': FileType.Image,
  'image/x-ms-bmp': FileType.Image,
  'image/svg+xml': FileType.Image,
  'image/webp': FileType.Image,
  'application/pdf': FileType.Pdf,
  'application/zip': FileType.ExternalLink,
  'application/x-7z-compressed': FileType.ExternalLink,
  'application/postscript': FileType.ExternalLink,
  'application/rtf': FileType.ExternalLink,
  'application/vnd.ms-excel': FileType.ExternalLink,
  'application/vnd.ms-powerpoint': FileType.ExternalLink,
  'application/vnd.wap.wmlc': FileType.ExternalLink,
  'application/vnd.google-earth.kml+xml': FileType.ExternalLink,
  'application/vnd.google-earth.kmz': FileType.ExternalLink,
  'application/x-cocoa': FileType.ExternalLink,
  'application/x-java-archive-diff': FileType.ExternalLink,
  'application/x-java-jnlp-file': FileType.ExternalLink,
  'application/x-makeself': FileType.ExternalLink,
  'application/x-perl': FileType.ExternalLink,
  'application/x-pilot': FileType.ExternalLink,
  'application/x-rar-compressed': FileType.ExternalLink,
  'application/x-redhat-package-manager': FileType.ExternalLink,
  'application/x-sea': FileType.ExternalLink,
  'application/x-shockwave-flash': FileType.ExternalLink,
  'application/x-stuffit': FileType.ExternalLink,
  'application/x-tcl': FileType.ExternalLink,
  'application/x-x509-ca-cert': FileType.ExternalLink,
  'application/x-xpinstall': FileType.ExternalLink,
  'application/xhtml+xml': FileType.ExternalLink,
  'application/octet-stream': FileType.ExternalLink,
  'audio/midi': FileType.Audio,
  'audio/mpeg': FileType.Audio,
  'audio/ogg': FileType.Audio,
  'audio/x-realaudio': FileType.Audio,
  'video/3gpp': FileType.Video,
  'video/mpeg': FileType.Video,
  'video/quicktime': FileType.Video,
  'video/x-flv': FileType.Video,
  'video/x-mng': FileType.Video,
  'video/x-ms-asf': FileType.Video,
  'video/x-ms-wmv': FileType.Video,
  'video/x-msvideo': FileType.Video,
  'video/mp4': FileType.Video,
}
let mimeTypes = {
  'html': 'text/html',
  'htm': 'text/html',
  'shtml': 'text/html',
  'css': 'text/css',
  'xml': 'text/xml',
  'gif': 'image/gif',
  'jpeg': 'image/jpeg',
  'jpg': 'image/jpeg',
  'js': 'application/x-javascript',
  'atom': 'application/atom+xml',
  'rss': 'application/rss+xml',
  'mml': 'text/mathml',
  'txt': 'text/plain',
  'jad': 'text/vnd.sun.j2me.app-descriptor',
  'wml': 'text/vnd.wap.wml',
  'htc': 'text/x-component',
  'png': 'image/png',
  'tif': 'image/tiff',
  'tiff': 'image/tiff',
  'wbmp': 'image/vnd.wap.wbmp',
  'ico': 'image/x-icon',
  'jng': 'image/x-jng',
  'bmp': 'image/x-ms-bmp',
  'svg': 'image/svg+xml',
  'webp': 'image/webp',
  'jar': 'application/java-archive',
  'war': 'application/java-archive',
  'ear': 'application/java-archive',
  'hqx': 'application/mac-binhex40',
  'doc': 'application/msword',
  'pdf': 'application/pdf',
  'ps': 'application/postscript',
  'eps': 'application/postscript',
  'ai': 'application/postscript',
  'rtf': 'application/rtf',
  'xls': 'application/vnd.ms-excel',
  'ppt': 'application/vnd.ms-powerpoint',
  'wmlc': 'application/vnd.wap.wmlc',
  'kml': 'application/vnd.google-earth.kml+xml',
  'kmz': 'application/vnd.google-earth.kmz',
  '7z': 'application/x-7z-compressed',
  'cco': 'application/x-cocoa',
  'jardiff': 'application/x-java-archive-diff',
  'jnlp': 'application/x-java-jnlp-file',
  'run': 'application/x-makeself',
  'pl': 'application/x-perl',
  'pm': 'application/x-perl',
  'prc': 'application/x-pilot',
  'pdb': 'application/x-pilot',
  'rar': 'application/x-rar-compressed',
  'rpm': 'application/x-redhat-package-manager',
  'sea': 'application/x-sea',
  'swf': 'application/x-shockwave-flash',
  'sit': 'application/x-stuffit',
  'tcl': 'application/x-tcl',
  'tk': 'application/x-tcl',
  'der': 'application/x-x509-ca-cert',
  'pem': 'application/x-x509-ca-cert',
  'crt': 'application/x-x509-ca-cert',
  'xpi': 'application/x-xpinstall',
  'xhtml': 'application/xhtml+xml',
  'zip': 'application/zip',
  'bin': 'application/octet-stream',
  'exe': 'application/octet-stream',
  'dll': 'application/octet-stream',
  'deb': 'application/octet-stream',
  'dmg': 'application/octet-stream',
  'eot': 'application/octet-stream',
  'iso': 'application/octet-stream',
  'img': 'application/octet-stream',
  'msi': 'application/octet-stream',
  'msp': 'application/octet-stream',
  'msm': 'application/octet-stream',
  'mid': 'audio/midi',
  'midi': 'audio/midi',
  'kar': 'audio/midi',
  'mp3': 'audio/mpeg',
  'ogg': 'audio/ogg',
  'ra': 'audio/x-realaudio',
  '3gpp': 'video/3gpp',
  '3gp': 'video/3gpp',
  'mpeg': 'video/mpeg',
  'mpg': 'video/mpeg',
  'mov': 'video/quicktime',
  'flv': 'video/x-flv',
  'mng': 'video/x-mng',
  'asx': 'video/x-ms-asf',
  'asf': 'video/x-ms-asf',
  'wmv': 'video/x-ms-wmv',
  'avi': 'video/x-msvideo',
  'm4v': 'video/mp4',
  'mp4': 'video/mp4',
}

export function detectExtension(url: string) {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}
export function detectFileType(url: string) {
  let extension = detectExtension(url);
  if (extension) {
    let mimeType = mimeTypes[extension.toLowerCase().trim()];
    if (mimeType) {
      return fileTypes[mimeType];
    }
  }
  return null;
}
export function getFileNameFromUrl(url: string) {
  let parts = url.split(/[#?]/)[0].split('.');
  return parts[parts.length - 2].split('/').pop();
}

export function getMimeTypeFromExtension(extension): string {
  return mimeTypes[extension.toLowerCase().trim()];
}
export function getFileTypeFromExtension(extension): FileType {
  return fileTypes[mimeTypes[extension.toLowerCase()]];
}
export function getFileInputTypeByMimeType(mimeType): FileType {
  return fileTypes[mimeType];
}

export function isNumber(value: any) {
  // A valid, better and more secure way to check numbers
  // https://stackoverflow.com/a/9716488/884588
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function fileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export function isValidImageFile(mimeType) {
  return ['image/png', 'image/gif', 'image/jpeg'].includes(mimeType);
}
export function isValidPDFFile(mimeType) {
  return ['application/pdf'].includes(mimeType);
}
export function isValidCSVFile(mimeType) {
  return ['text/csv'].includes(mimeType);
}
export function approximateFileSizeFromBase64String(base64String: string) {
  let stringLength = base64String.length - 'data:image/png;base64,'.length;
  return 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
}
export function getSelectedIdList(kinds: CheckListItemViewModel[]): number[] {
  return kinds?.filter(p => p.value == true).map(d => d.key);
}

import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { ApproveEntityType } from "src/app/enums/approve-entity-type";
import { Constants } from "src/app/enums/constants";
import { FilterType } from "src/app/enums/filter-type.enum";
import { SiteInductionStatus } from "src/app/enums/site-induction-status";
import { Table } from "src/app/enums/table.enum";
import { UserPermission } from "src/app/enums/user-permission.enum";
import { AttendanceDocumentReportViewModel } from "src/app/models/attendance-document-report-viewmodel";
import { InitialFilter, GridColumn, GridConfig, InitialFilterUsage, GridTemplate } from "src/app/models/grid/grid.config";
import { AsyncResult } from "src/app/models/helper-models";
import { SiteInductionFullInfoViewModel } from "src/app/models/induction/site-induction-full-info-view-model";
import { QuestionAnswerInputModel } from "src/app/models/question-answer-inputmodel";
import { LayoutService } from "src/app/services/layout.service";
import { ModalService } from "src/app/services/modal.service";
import { UserService } from "src/app/services/user.service";
import { ApproveService } from "../../../services/approve-service";
import { ApproveEntityTypePipe } from 'src/app/pipes/approve-entity-type.pipe';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'obc-induction-report',
  templateUrl: './induction-report.component.html',
  styleUrls: ['./induction-report.component.scss'],
})
export class InductionReportComponent implements OnInit {
  @Input() selectedUserId: number = null;
  @Input() siteIdList: number[] = [];
  @Input() isReportMode: boolean = false;
  @ViewChild('grid') grid: any;
  includeExpiredInductions: boolean = false;
  includeInactiveInductions: boolean = false;
  selectedSiteInductionId: number;

  inProgress: boolean = false;
  isRefreshRequired: boolean = false;
  questionAnswerInputModel: QuestionAnswerInputModel = new QuestionAnswerInputModel();
  initialFilters: InitialFilter[] = [];
  UserPermission = UserPermission;
  SiteInductionStatus = SiteInductionStatus;

  constructor(
    private layoutService: LayoutService,
    private modalService: ModalService,
    public userService: UserService,
    public approveService: ApproveService,
    private approveEntityTypePipe: ApproveEntityTypePipe,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    if (!this.isReportMode)
      this.layoutService.header = 'Induction Report';

    this.prepareGridConfig();
  }

  gridColumns: GridColumn<AttendanceDocumentReportViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;

  prepareGridConfig() {
    let currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 1);

    if (!this.isReportMode) {
      this.initialFilters.push(
        {
          key: 'status', value: [
            { label: 'Approved', key: SiteInductionStatus.ApprovedByHost },
            { label: 'Auto Approved', key: SiteInductionStatus.AutoApproved },
            { label: 'Pending Approval', key: SiteInductionStatus.PendingToApprove },
          ],
          displayInFilterRenderer: true,
        },
      );
    }

    this.initialFilters.push(
      { key: 'includeInactiveInductionForms', value: false, },
      {
        key: 'includeExpiredInductions',
        value: this.isReportMode,
        displayInFilterRenderer: false,
      },
      {
        key: 'includeQuestionAnswers',
        value: true,
        displayInFilterRenderer: false,
        usage: InitialFilterUsage.CSV,
      });

    if (this.selectedUserId) {
      this.initialFilters.push({ key: 'userIds', value: [this.selectedUserId], displayInFilterRenderer: false } as InitialFilter);
    }
    if (this.siteIdList?.length ?? 0) {
      this.initialFilters.push({ key: 'siteIds', value: this.siteIdList, displayInFilterRenderer: false } as InitialFilter);
    }

    this.gridConfig = new GridConfig({
      apiUrl: this.isReportMode ? '/api/workerprofile/site-induction-report' : '/api/Induction/site-induction-report',
      tableType: Table.InductionReport,
      forceHideAllControlButtons: this.isReportMode,
      displayQuestionsFilter: true,
      displayGeneralSearch: true,
      generalSearchPlaceholder: "Filter result by Induction Title, Induction Number, User, Supplier and Site fields",
      generalSearchMinimumCharsToSearch: 1,
      initialFilters: this.initialFilters,
      csvReportUrl: '/api/Induction/site-induction-report-csv-as-job',
      cacheKeyPrefix: this.isReportMode ? Constants.WORKER_PROFILE_REPORT_PREFIX : '',
      hideEmptyColumns: !this.isReportMode,
      apiResultCallback: (res) => {
        return {
          data: res?.data?.data,
          totalCount: res?.data?.totalCount,
        }
      }
    });
    this.gridColumns = [
      {
        name: "Induction Title",
        key: "inductionTitle",
        type: FilterType.InductionId,
        gridTemplate: new GridTemplate().ComponentTemplateName('inductionTitle')
          .CsvFields([
            'inductionTitle',
            'isExpired',
          ]),
        propertyNameInQuery: 'inductionIds',
      },
      {
        name: "Induction Number",
        key: "inductionKey",
        enableFilter: false,
        enableSort: false,
      },
      !this.isReportMode ? {
        name: "Full Name",
        key: "user",
        type: FilterType.User,
        propertyNameInQuery: 'userIds',
        populateData: (row) => {
          return {
            userMobile: row.userMobile,
            fullName: row.user,
            userId: row.userId,
          }
        },
        gridTemplate: new GridTemplate().FullNameMobile({
          fullName: 'user',
          mobile: 'userMobile',
          userId: 'userId'
        }).CsvFields([
          'user',
          'userMobile',
        ]),
      } : null,
      {
        name: "Supplier",
        key: "supplierName",
        sortBy: "supplier",
        type: FilterType.Supplier,
        propertyNameInQuery: 'suppliers',
      },
      {
        name: "Date",
        key: "checkinDate",
        type: FilterType.DateTimeRange,
        propertyNameInQuery: 'checkinDateRange',
        isArray: false,
      },
      {
        name: "Expiration Date",
        key: "expiredDate",
        type: FilterType.DateTimeRange,
        propertyNameInQuery: 'expiredDateRange',
        isArray: false,
        visible: false,
      },
      {
        name: "Status",
        key: "status",
        sortBy: "currentStatus",
        type: FilterType.Induction,
        gridTemplate: new GridTemplate().ComponentTemplateName('currentStatus'),
        propertyNameInQuery: 'currentStatusIds',
      },
      {
        name: "Site",
        key: "siteName",
        sortBy: "site",
        type: FilterType.Site,
        propertyNameInQuery: 'siteIds',
        gridTemplate: new GridTemplate().SiteNameReference({
          siteName: 'site',
          siteReference: 'siteReference',
        }).CsvFields([
          'site',
          'siteReference',
        ]),
      },
      {
        name: "Actions",
        key: 'viewDetails',
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('viewDetails'),
        visible: true,
      }
    ].filter((q) => !!q);
  }


  openModal(template, siteInductionId: number, cssStyle: string = 'modal-lg') {
    this.isRefreshRequired = false;
    this.selectedSiteInductionId = siteInductionId;
    this.modalService.show(template, cssStyle)
      .subscribe((res) => {
        if (this.isRefreshRequired) {
          this.grid?.loadData();
          this.isRefreshRequired = false;
        }
      });
  }

  closeModal(_: AsyncResult) {
    this.modalService.hide();
  }

  hasResetPermission(siteId: number): boolean {
    return this.userService.hasAdminPermissionOnSite(siteId);
  }

  resetInductionStatus(siteInductionId: number) {
    const entityTypeTitle = this.approveEntityTypePipe.transform(ApproveEntityType.Induction);
    this.modalService
      .confirm(
        'Are you sure you want to reset this induction to pending status?'
      )
      .subscribe((confirm) => {
        if (confirm) {
          this.inProgress = true;
          let subscription =
            this.approveService
              .resetEntityStatus({ entityId: siteInductionId, approveEntityType: ApproveEntityType.Induction })
              .subscribe(res => {
                if (res)
                  this.toastr.success(`${entityTypeTitle} status has successfully reset!`);
                else
                  this.toastr.error(`Can not reset ${entityTypeTitle} status!`)
              }, _ => {
                this.toastr.error(`Can not reset ${entityTypeTitle} status! Some error happened`)
              }, () => {
                subscription.unsubscribe();
                this.fetchPendingList();
                this.inProgress = false;
              })
        }
      });
  }

  fetchPendingList() {
    this.grid?.loadData();
  }

  onInductionUpdated() {
    this.isRefreshRequired = true;
  }

  isShowTemplateColumns(mainColumn, detailColumn) {
    return this.grid.isShowTemplateColumns(mainColumn, detailColumn);
  }

  onIncludeExpiredInductionsChanged(value: boolean) {
    this.initialFilters.find(x => x.key == "includeExpiredInductions").value = value;
    this.grid?.loadData();
  }

  onIncludeInactiveInductionsChanged(value: boolean) {
    this.gridColumns.find(x => x.key == "inductionTitle").filterSettings = { includeInactiveItems: value };
    this.initialFilters.find(x => x.key == "includeInactiveInductionForms").value = value;
    this.grid?.loadData();
  }

  isReadyToRequestRevision(item: SiteInductionFullInfoViewModel) {
    return (item.status == SiteInductionStatus.ApprovedByHost || item.status == SiteInductionStatus.AutoApproved) &&
      !item.isExpired;
  }
  ApproveEntityType = ApproveEntityType;
}

import { CompanyEmployeeComponent } from './components/site-permissions/company-employee/company-employee.component';
import { ToAnyPipe } from './pipes/to-any.pipe';
import { DicToArrayPipe } from './pipes/dic-to-array.pipe';
import { AnonymousCheckoutComponent } from './components/anonymous-check/anonymous-checkout/anonymous-checkout.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SecureComponent } from './layout/secure/secure.component';
import { PublicComponent } from './layout/public/public.component';
import { SideBarComponent } from './layout/side-bar/side-bar.component';
import { TopBarComponent } from './layout/top-bar/top-bar.component';
import { SwitchComponent } from './custom-controls/switch/switch.component';
import { RadioGroupComponent } from './custom-controls/radio-group/radio-group.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CompanyComponent } from './components/company/company.component';
import { SiteComponent } from './components/site/site-details/site.component';
import { UserComponent } from './components/user/user.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpHelperInterceptor } from './services/http-helper.interceptor';
import { LoginComponent } from './auth/login/login.component';
import { SignInService } from './services/sign-in.service';
import { ModalComponent } from './custom-controls/modal/modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SiteQuestionsManageComponent } from './components/site/site-questions-manage/site-questions-manage.component';
import { SiteQuestionsComponent } from './components/site/site-questions/site-questions.component';
import { QuestionManagerComponent } from './components/question/question-manager/question-manager.component';
import { QuestionAddComponent } from './components/question/question-add/question-add.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { QuestionTypePipe } from './pipes/question-type.pipe';
import { QuestionMultiSelectComponent } from './components/question/question-multi-select/question-multi-select.component';
import { QuestionTextComponent } from './components/question/question-text/question-text.component';
import { QuestionBooleanComponent } from './components/question/question-boolean/question-boolean.component';
import { QuestinRenderModePipe } from './pipes/questin-render-mode.pipe';
import { QuestionSingleSelectComponent } from './components/question/question-single-select/question-single-select.component';
import { NgxBootstrapModule } from './shared-modules/ngx-bootstrap.module';
import { SiteAttendancePermissionComponent } from './components/site-permissions/site-attendance-permission/site-attendance-permission.component';
import { ImportSiteAttendancePermissionComponent } from './components/site-permissions/site-attendance-permission/import-site-attendance-permission/import-site-attendance-permission.component';
import { ManageSiteAttendancePermissionComponent } from './components/site-permissions/site-attendance-permission/manage-site-attendance-permission/manage-site-attendance-permission.component';
import { LoaderComponent } from './custom-controls/loader/loader.component';
import { QuestionAnswerComponent } from './components/question-answer/question-answer.component';
import { SiteActivityComponent } from './components/site-activity/site-activity.component';
import { SiteAttendanceComponent } from './components/site-attendance/site-attendance.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from './custom-controls/map/map.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UserActivityComponent } from './components/user-activity/user-activity.component';
import { UserDocumentTypesComponent } from './components/user-document-types/user-document-types.component';
import { ChartModule } from 'angular2-chartjs';
import { GodComponent } from './components/god/god.component';
import { ManageCompaniesComponent } from './components/god/manage-companies/manage-companies.component';
import { UserAutocompleteComponent } from './custom-controls/user-autocomplete/user-autocomplete.component';
import { DatetimePipe } from './pipes/datetime.pipe';
import { QuestionFileComponent } from './components/question/question-file/question-file.component';
import { SiteAttendanceBlockedUserComponent } from './components/site-permissions/site-attendance-blocked-user/site-attendance-blocked-user.component';
import { AccompanierModePipe } from './pipes/accompanier-mode.pipe';
import { AutomaticCheckinModePipe } from './pipes/automatic-checkin-mode.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TooltipComponent } from './custom-controls/tooltip/tooltip.component';
import { ManageVideosComponent } from './components/manage-videos/manage-videos.component';
import { VideoPlayListButtonComponent } from './components/video-play-list-button/video-play-list-button.component';
import { LightBoxComponent } from './components/light-box/light-box.component';
import { VideoListComponent } from './components/video-list/video-list.component';
import { QuestionRendererComponent } from './components/question/renderer/question-renderer/question-renderer.component';
import { BooleanQuestionRendererComponent } from './components/question/renderer/boolean-question-renderer/boolean-question-renderer.component';
import { MultiSelectQuestionRendererComponent } from './components/question/renderer/multi-select-question-renderer/multi-select-question-renderer.component';
import { SingleSelectQuestionRendererComponent } from './components/question/renderer/single-select-question-renderer/single-select-question-renderer.component';
import { ManualCheckinComponent } from './components/manual-check/manual-checkin/manual-checkin.component';
import { ManualCheckoutComponent } from './components/manual-check/manual-checkout/manual-checkout.component';
import { ManualCheckComponent } from './components/manual-check/manual-check.component';
import { TextQuestionRendererComponent } from './components/question/renderer/text-question-renderer/text-question-renderer.component';
import { FileQuestionRendererComponent } from './components/question/renderer/file-question-renderer/file-question-renderer.component';
import { QuestionTriggersComponent } from './components/question/question-triggers/question-triggers.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { ObjectToArrayPipe } from './pipes/object-to-array.pipe';
import { DescriptorComponent } from './custom-controls/descriptor/descriptor.component';
import { CompanyUserComponent } from './components/site-permissions/company-user/company-user.component';
import { MobilePipe } from './pipes/mobile.pipe';
import { ChartComponent } from './custom-controls/chart/chart.component';
import { WeekdayPipe } from './pipes/weekday.pipe';
import { FlaggedUserComponent } from './components/site-permissions/flagged-user/flagged-user.component';
import { CompanyOwnerComponent } from './components/company-owner/company-owner.component';
import { BillingComponent } from './components/company-owner/billing/billing.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { RegexPipe } from './pipes/regex.pipe';
import { CompanySettingsComponent } from './components/company-settings/company-settings.component';
import { CompanyTriggerActionComponent } from './components/company-settings/company-trigger-action/company-trigger-action.component';
import { EmailTemplateComponent } from './components/company-settings/company-trigger-action/email-template/email-template.component';
import { CompanyProfileComponent } from './components/company-settings/company-profile/company-profile.component';
import { FilterDataListComponent } from './custom-controls/filter-data-list/filter-data-list.component';
import { ManagePlansComponent } from './components/god/manage-plans/manage-plans.component';
import { SiteAutomationsComponent } from './components/company-owner/site-automations/site-automations.component';
import { AskQuestionPeriodModePipe } from './pipes/ask-question-period-mode.pipe';
import { ItemValuePipe } from './pipes/item-value.pipe';
import { ChipContainerComponent } from './custom-controls/chip-container/chip-container.component';
import { FilterPipe } from './pipes/filter.pipe';
import { EmergencyAlarmComponent } from './components/emergency-alarm/emergency-alarm.component';
import { QuestionExtendedTitleComponent } from './components/question/question-extended-title/question-extended-title.component';
import { DraggableDirective } from './directive/draggable.directive';
import { ResizableDirective } from './directive/resizable.directive';
import { CardDesignerComponent } from './components/designer/card-designer/card-designer.component';
import { DraggableElementPageComponent } from './components/designer/draggable-element-page/draggable-element-page.component';
import { AccompanyPersonComponent } from './components/accompany-person/accompany-person.component';
import { AnonymousComponent } from './layout/anonymouse/anonymous.component';
import { AnonymousCheckComponent } from './components/anonymous-check/anonymous-check.component';
import { AnonymousCheckinComponent } from './components/anonymous-check/anonymous-checkin/anonymous-checkin.component';
import { RequiredDocumentRendererComponent } from './components/question/renderer/required-document-renderer/required-document-renderer.component';
import { SuccessfullAnonymousCheckinComponent } from './components/anonymous-check/anonymous-checkin/successfull-anonymous-checkin/successfull-anonymous-checkin.component';
import { LayoutPageComponent } from './components/designer/layout-page/layout-page.component';
import { ManageIndustryTypesComponent } from './components/god/manage-industry-types/manage-industry-types.component';
import { QuestionDefaultModePipe } from './pipes/question-default-mode.pipe';
import { AnnouncementComponent } from './components/announcement-manager/announcement/announcement.component';
import { AnnouncementManagerComponent } from './components/announcement-manager/announcement-manager.component';
import { SiteAnnouncementComponent } from './components/announcement-manager/site-announcement/site-announcement.component';
import { CreateAnnouncementComponent } from './components/announcement-manager/create-announcement/create-announcement.component';
import { SiteAnnouncementManageComponent } from './components/site/site-announcement-manage/site-announcement-manage.component';
import { OrderByFuctionPipe, OrderByPipe } from './pipes/order-by.pipe';
import { UserContactInformationComponent } from './components/user-contact-information/user-contact-information.component';
import { RequiredFieldRendererComponent } from './components/required-field/required-field-renderer/required-field-renderer.component';
import { SummaryWidgetComponent } from './custom-controls/summary-widget/summary-widget.component';
import { DatePipe } from './pipes/date.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { MultiImageModePipe } from './pipes/multi-image-mode-pipe.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { FilterByPropertyPipe } from './pipes/filter-by-property.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { ManageVisitorTypeComponent } from './components/manage-visitor-type/manage-visitor-type.component';
import { ManageSiteVisitorTypeComponent } from './components/manage-site-visitor-type/manage-site-visitor-type.component';
import { ManageSiteQuestionVisitorTypeComponent } from './components/manage-site-question-visitor-type/manage-site-question-visitor-type.component';
import { ManageScannablesComponent } from './components/scannables/manage-scannables/manage-scannables.component';
import { ScannableGroupsComponent } from './components/scannables/scannable-groups/scannable-groups.component';
import { ScannableItemsComponent } from './components/scannables/scannable-items/scannable-items.component';
import { FileUploadComponent } from './custom-controls/file-upload/file-upload.component';
import { SiteScannableGroupComponent } from './components/scannables/site-scannable-group/site-scannable-group.component';
import { GroupByPipe } from './pipes/group-by.pipe';
import { ImageCropperFormControlComponent } from './custom-controls/image-cropper-form-control/image-cropper-form-control.component';
import { CompanyCategoryPipe } from './pipes/company-category.pipe';
import { AddRemoveQuestionAllSitesComponent } from './components/question/add-remove-question-all-sites/add-remove-question-all-sites.component';
import { SiteLocationVerifyComponent } from './components/site/site-location-verify/site-location-verify.component';
import { ImportSitesComponent } from './components/site/import-sites/import-sites.component';
import { CheckListFormControlComponent } from './custom-controls/check-list-form-control/check-list-form-control.component';
import { DurationSelectorComponent } from './custom-controls/duration-selector/duration-selector.component';
import { ArrayFormControlComponent } from './custom-controls/array-form-control/array-form-control.component';
import { NicyNullPipe } from './pipes/nicy-null.pipe';
import { HourMinuteSelectorComponent } from './custom-controls/hour-minute-selector/hour-minute-selector.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MyScheduledReportsComponent } from './components/site-activity/my-scheduled-reports/my-scheduled-reports.component';
import { ReportDeliveryTypePipe } from './pipes/report-delivery-type.pipe';
import { ReportPeriodTypePipe } from './pipes/report-period-type.pipe';
import { NumberToWeekdayPipe } from './pipes/number-to-weekday.pipe';
import { FilterSiteByPermissionPipe } from './pipes/filter-site-by-permission.pipe';
import { SiteUserComponent } from './components/site-permissions/site-user/site-user.component';
import { UserBusinessComponent } from './custom-controls/user-business/user-business.component';
import { EmailListDirective } from './directive/email-list.directive';
import { SubscriptionTypePipePipe } from './pipes/subscription-type-pipe.pipe';
import { QuickSetupComponent } from './components/quick-setup/quick-setup.component';
import { AdditionalCompanyHostComponent } from './components/quick-setup/additional-company-host/additional-company-host.component';
import { MinimalAddSiteComponent } from './components/quick-setup/minimal-add-site/minimal-add-site.component';
import { ToFormGroupPipe } from './pipes/to-form-group.pipe';
import { AddressFormControlComponent } from './custom-controls/address-form-control/address-form-control.component';
import { AttendanceFieldRendererComponent } from './components/attendance-field-renderer/attendance-field-renderer.component';
import { AttendanceFieldComponent } from './components/attendance-field-renderer/attendance-field/attendance-field.component';
import { HostPushNotificationComponent } from './components/host-push-notification/host-push-notification.component';
import { FilteredAnnouncementComponent } from './components/site-activity/filtered-announcement/filtered-announcement.component';
import { DocumentExpiryStatusPipe } from './pipes/document-expiry-status.pipe';
import { DocumentReportComponent } from './components/document-report/document-report.component';
import { PaginationComponent } from './custom-controls/pagination/pagination.component';
import { SiteSelectorComponent } from './components/site-selector/site-selector.component';
import { ConditionalQuestionComponent } from './components/question/conditional-question/conditional-question.component';
import { AnswerSelectorComponent } from './custom-controls/answer-selector/answer-selector.component';
import { CompanySiteSelectorComponent } from './custom-controls/company-site-selector/company-site-selector.component';
import { PopoverFilterSimpleComponent } from './custom-controls/popover-filter/popover-filter-simple/popover-filter-simple.component';
import { FilterHeaderComponent } from './custom-controls/popover-filter/popover-filter-simple/filter-header/filter-header.component';
import { SortableTitleHeaderComponent } from './custom-controls/sortable-title-header/sortable-title-header.component';
import { FilterListRendererComponent } from './custom-controls/filter-list-renderer/filter-list-renderer.component';
import { AnnouncementModePipe } from './pipes/announcement-mode.pipe';
import { TableSettingComponent } from './custom-controls/grid/table-setting/table-setting.component';
import { DurationFormControlComponent } from './custom-controls/duration-form-control/duration-form-control.component';
import { DateTimePickerComponent } from './custom-controls/date-time-picker/date-time-picker.component';
import { InputDateTimePickerComponent } from './custom-controls/input-date-time-picker/input-date-time-picker.component';
import { AskModePipe } from './pipes/ask-mode.pipe';
import { CompanySupplierComponent } from './components/company-supplier/company-supplier.component';
import { EditCompanySupplierComponent } from './components/company-supplier/edit-company-supplier/edit-company-supplier.component';
import { CompanySupplierSelectorComponent } from './custom-controls/company-supplier/company-supplier-selector.component';
import { InductionModePipe } from './pipes/site-induction-mode.pipe';
import { InductionStatusComponent } from './custom-controls/induction-status/induction-status.component';
import { DataRetentionPolicyComponent } from './components/data-retention-policy/data-retention-policy.component';
import { SiteInductionManageComponent } from './components/site-induction-manage/site-induction-manage.component';
import { SiteInductionComponent } from './components/site/site-induction/site-induction.component';
import { InductionRendererComponent } from './components/question/induction-renderer/induction-renderer.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { AnnouncementRendererComponent } from './components/announcement-renderer/announcement-renderer.component';
import { AnnouncementItemRendererComponent } from './components/announcement-renderer/announcement-item-renderer/announcement-item-renderer.component';
import { QuestionSelectorComponent } from './components/question-selector/question-selector.component';
import { DocumentSelectorComponent } from './components/document-selector/document-selector.component';
import { AnnouncementSelectorComponent } from './components/announcement-selector/announcement-selector.component';
import { TextOperatorPipe } from './pipes/text-operator.pipe';
import { DraggableItemService, SortableModule } from 'ngx-bootstrap/sortable';
import { UserSelectorComponent } from './custom-controls/user-selector/user-selector.component';
import { RequiredDocumentListRendererComponent } from './components/question/renderer/required-document-list-renderer/required-document-list-renderer.component';
import { VisitorTypeSelectorComponent } from './components/visitor-type-selector/visitor-type-selector.component';
import { DisputeAttendanceComponent } from './components/dispute-attendance/dispute-attendance.component';
import { MarkdownEditorComponent } from './components/markdown-editor/markdown-editor.component';
import { QuestionTitleRendererComponent } from './components/question/renderer/question-title-renderer/question-title-renderer.component';
import { DisputeHistoryComponent } from './components/dispute-history/dispute-history.component';
import { SortableComponent } from './custom-controls/sortable/sortable.component';
import { ManageInventoryComponent } from './components/inventory/manage-inventory/manage-inventory.component';
import { ManageInventoryLocationsComponent } from './components/inventory/manage-inventory-locations/manage-inventory-locations.component';
import { ManageToolCategoriesComponent } from './components/inventory/manage-tool-categories/manage-tool-categories.component';
import { ManageToolStatusesComponent } from './components/inventory/manage-tool-statuses/manage-tool-statuses.component';
import { InventoryToolReportComponent } from './components/inventory/reports/inventory-tool-report/inventory-tool-report.component';
import { InventoryLocationSelectorComponent } from './components/inventory/inventory-location-selector/inventory-location-selector.component';
import { ToolAssignHistoryComponent } from './components/inventory/reports/tool-assign-history/tool-assign-history.component';
import { ToolImagesComponent } from './components/inventory/reports/tool-images/tool-images.component';
import { ToolDynamicFieldTypePipe } from './pipes/tool-dynamic-field-type.pipe';
import { UpsertInventoryAssetComponent } from './components/inventory/upsert-inventory-asset/upsert-inventory-asset.component';
import { ToolFieldRendererComponent } from './components/inventory/tool-field-renderers/tool-field-renderer/tool-field-renderer.component';
import { TextToolFieldRendererComponent } from './components/inventory/tool-field-renderers/text-tool-field-renderer/text-tool-field-renderer.component';
import { ImageToolFieldRendererComponent } from './components/inventory/tool-field-renderers/image-tool-field-renderer/image-tool-field-renderer.component';
import { FormManageComponent } from './components/form-manage/form-manage/form-manage.component';
import { CompanySiteScopePipe } from './pipes/company-site-scope.pipe';
import { SiteFormsComponent } from './components/site/site-forms/site-forms.component';
import { FormInjectorComponent } from './components/form-manage/form-injector/form-injector.component';
import { InventoryStatusTriggerEditorComponent } from './components/inventory/inventory-status-trigger-editor/inventory-status-trigger-editor.component';
import { InventoryStatusTriggersComponent } from './components/inventory/inventory-status-triggers/inventory-status-triggers.component';
import { ScheduleReportTypePipe } from './pipes/schedule-report-type.pipe';
import { InventoryDynamicFieldTriggersComponent } from './components/inventory/inventory-dynamic-filed-triggers/inventory-dynamic-field-triggers.component';
import { InventoryDynamicFieldTriggerEditorComponent } from './components/inventory/inventory-dynamic-field-trigger-editor/inventory-dynamic-field-trigger-editor.component';
import { SiteInductionStatusPipe } from './pipes/site-induction-status.pipe';
import { InductionManageComponent } from './components/induction/induction-manage/induction-manage.component';
import { UpsertInductionComponent } from './components/induction/upsert-induction/upsert-induction.component';
import { InductionSelectorComponent } from './components/induction/induction-selector/induction-selector.component';
import { InductionReportComponent } from './components/induction/induction-report/induction-report.component';
import { InductionFilledInfoComponent } from './components/induction/induction-filled-info/induction-filled-info.component';
import { SiteInductionDetailComponent } from './components/induction/site-induction-detail/site-induction-detail.component';
import { SimpleDocumentRendererComponent } from './components/simple-document-renderer/simple-document-renderer.component';
import { FormTitlePipe } from './pipes/form-title.pipe';
import { ManageSupplierDocumentTypeComponent } from './components/supplier-documents/manage-supplier-document-type/manage-supplier-document-type.component';
import { ManageSupplierDocumentsComponent } from './components/supplier-documents/manage-supplier-documents/manage-supplier-documents.component';
import { SiteSupplierDocumentUploaderComponent } from './components/supplier-documents/site-supplier-document-uploader/site-supplier-document-uploader.component';
import { SupplierDocumentsComponent } from './components/supplier-documents/supplier-documents.component';
import { SupplierDocumentSelectorComponent } from './custom-controls/supplier-document-selector/supplier-document-selector.component';
import { SupplierDocumentSignOffViewerComponent } from './components/supplier-documents/supplier-document-sign-off-viewer/supplier-document-sign-off-viewer.component';
import { ViewSiteSupplierDocumentVersionComponent } from './components/supplier-documents/view-site-supplier-document-version/view-site-supplier-document-version.component';
import { SupplierDocumentRendererComponent } from './components/supplier-documents/supplier-document-renderer/supplier-document-renderer.component';
import { SiteSupplierDocumentUploaderContainerComponent } from './components/supplier-documents/site-supplier-document-uploader-container/site-supplier-document-uploader-container.component';
import { SiteDocumentStatusPipe } from './pipes/site-document-status.pipe';
import { SiteAttendanceApprovalStatusComponent } from './custom-controls/site-attendance-approval-status/site-attendance-approval-status.component';
import { ClipboardBoxComponent } from './custom-controls/clipboard-box/clipboard-box.component';
import { SiteAttendanceApprovalStatusPipe } from './pipes/site-attendance-approval-status.pipe';
import { SupplierDocumentTypeStatusIconPipe } from './pipes/document-type-status-icon.pipe';
import { SupplierDocumentTypeStatusTooltipPipe } from './pipes/supplier-document-type-status-tooltip.pipe';
import { ManageRegionComponent } from './components/region/manage-region/manage-region.component';
import { RegionSelectorComponent } from './components/region-selector/region-selector.component';
import { ViewSiteSupplierDocumentVersionHistoryComponent } from './components/supplier-documents/view-site-supplier-document-version-history/view-site-supplier-document-version-history.component';
import { MyobPeriodTypePipe } from './pipes/myob-period-type.pipe';
import { TriggerSelectorComponent } from './components/trigger-selector/trigger-selector.component';
import { FormReportComponent } from './components/form-manage/form-report/form-report.component';
import { FormSelectorComponent } from './components/form-manage/form-selector/form-selector.component';
import { FormDataDetailsComponent } from './components/form-manage/form-data-details/form-data-details.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { FormScopePipe } from './pipes/form-scope.pipe';
import { SupplierDocumentReviewComponent } from './components/supplier-documents/supplier-document-review/supplier-document-review.component';
import { FormDataRendererComponent } from './components/form-manage/form-data-renderer/form-data-renderer.component';
import { FormDetailsComponent } from './components/form-manage/form-details/form-details.component';
import { AnnouncementDisplayModePipe } from './pipes/announcement-display-mode.pipe';
import { ItemsMixtureComponent } from './components/items-mixture/items-mixture.component';
import { ItemsRendererComponent } from './components/items-renderer/items-renderer.component';
import { SingleQuestionRendererComponent } from './components/question/renderer/single-question-renderer/single-question-renderer.component';
import { SiteManagementComponent } from './components/site-management/site-management.component';
import { SiteQuestionsManagementComponent } from './components/site-management/site-questions-management/site-questions-management.component';
import { ToastrModule } from 'ngx-toastr';
import { AccordionHeaderComponent } from './components/site-management/accordion-header/accordion-header.component';
import { CompanyIntegrationsComponent } from './components/company-integrations/company-integrations.component';
import { MyobIntegrationComponent } from './components/company-integrations/myob-integration/myob-integration.component';
import { ProcoreIntegrationComponent } from './components/company-integrations/procore-integration/procore-integration.component';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { CompanyInductionSelectorComponent } from './custom-controls/company-induction-selector/company-induction-selector.component';
import { SiteSupplierDocumentMultipleUploaderComponent } from './components/supplier-documents/site-supplier-document-multiple-uploader/site-supplier-document-multiple-uploader.component';
import { FormAskModePipe } from './pipes/form-ask-mode.pipe';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SupplierDocumentReminderComponent } from './components/supplier-documents/supplier-document-reminder/supplier-document-reminder.component';
import { PendingToApproveListComponent } from './components/pending-approval/pending-to-approve-list/pending-to-approve-list.component';
import { ApproveEntityTypePipe } from './pipes/approve-entity-type.pipe';
import { SupplierDocumentDescriptionViewerComponent } from './components/supplier-documents/supplier-document-description-viewer/supplier-document-description-viewer.component';
import { HtmlNewLinePipe } from './pipes/html-new-line.pipe';
import { CompanyDocumentTypeComponent } from './components/company-settings/company-document-type/company-document-type.component';
import { OptionalMandatorySelectorComponent } from './custom-controls/optional-mandatory-selector/optional-mandatory-selector.component';
import { OptionalMandatoryPipe } from './pipes/optional-mandatory.pipe';
import { ProcoreExportSiteAttendanceAsTimeCardModePipePipe } from './pipes/procore-export-site-attendance-as-time-card-mode-pipe.pipe';
import { ProcoreExportSiteAttendanceAsVisitorLogModePipePipe } from './pipes/procore-export-site-attendance-as-visitor-log-mode-pipe.pipe';
import { ProcoreExportSiteAttendanceAsManpowerModePipe } from './pipes/procore-export-site-attendance-as-manpower-mode-pipe.pipe';
import { ProcoreConfigurationAccessCodeCallbackComponent } from './components/company-integrations/procore-configuration-access-code-callback/procore-configuration-access-code-callback.component';
import { MyobConfigurationAccessCodeCallbackComponent } from './components/company-integrations/myob-configuration-access-code-callback/myob-configuration-access-code-callback.component';
import { FormTypePipe } from './pipes/form-type.pipe';
import { FormApplyForPipe } from './pipes/form-apply-for.pipe';
import { AnonymousPermitComponent } from './components/anonymous-permit/anonymous-permit.component';
import { FilledFormStatusPipe } from './pipes/filled-form-status.pipe';
import { FormRegisterForPipe } from './pipes/form-register-for.pipe';
import { FormDataStatusPipe } from './pipes/form-data-status.pipe';
import { AutoCheckOutTypePipe } from './pipes/auto-check-out-type.pipe';
import { PermitStatusIconPipe } from './pipes/permit-status-icon.pipe';
import { PermitStatusTitlePipe } from './pipes/permit-status-title.pipe';
import { PermitStatusColorPipe } from './pipes/permit-status-color.pipe';
import { DelayAnswerModePipe } from './pipes/delay-answer-mode.pipe';
import { QuestionDateComponent } from './components/question/question-date/question-date.component';
import { DateQuestionLimitComponent } from './components/question/question-date/date-question-limit/date-question-limit.component';
import { DateQuestionRendererComponent } from './components/question/renderer/date-question-renderer/date-question-renderer.component';
import { DocumentAnswerRendererComponent } from './components/question-answer/document-answer-renderer/document-answer-renderer.component';
import { QuestionListAnswerRendererComponent } from './components/question-answer/question-list-answer-renderer/question-list-answer-renderer.component';
import { SupplierDocumentApprovalModePipe } from './pipes/supplier-document-approval-mode.pipe';
import { FilterByConditionPipe } from './pipes/filter-by-condition.pipe';
import { PreCheckInListComponent } from './components/pre-check-in-list/pre-check-in-list.component';
import { SignaturePadComponent } from './custom-controls/signature-pad/signature-pad.component';
import { ApproveStatusPipe } from './pipes/approve-status.pipe';
import { ApproveRejectComponent } from './components/pending-approval/approve-reject/approve-reject.component';
import { PermitOpenPolicyPipe } from './pipes/permit-open-policy.pipe';
import { PermitStatusPipe } from './pipes/permit-status.pipe';
import { PermitReportComponent } from './components/form-manage/permit/permit-report/permit-report.component';
import { DaysOfWeekPipe } from './pipes/days-of-week.pipe';
import { PermitScheduleSelectorComponent } from './components/permit-schedule-selector/permit-schedule-selector.component';
import { PermitScheduleItemComponent } from './components/permit-schedule-selector/permit-schedule-item/permit-schedule-item.component';
import { UpdatePermitStatusComponent } from './components/form-manage/permit/update-permit-status/update-permit-status.component';
import { PermitHistoryComponent } from './components/form-manage/permit/permit-history/permit-history.component';
import { TimePipe } from './pipes/time.pipe';
import { QRCodeCheckinModePipe } from './pipes/qrcode-checkin-mode.pipe';
import { CheckinLocationModePipe } from './pipes/checkin-location-mode.pipe';
import { SiteAnnouncementForceViewModePipe } from './pipes/site-announcement-force-view-mode.pipe';
import { QuestionAnswerFilterComponent } from './components/question-answer/question-answer-filter/question-answer-filter.component';
import { QuestionAnswerFilterRendererComponent } from './components/question-answer/question-answer-filter-renderer/question-answer-filter-renderer.component';
import { SiteAnnouncementDisplayTimePipe } from './pipes/site-announcement-display-time.pipe';
import { CheckinAnnouncementRendererComponent } from './components/announcement-renderer/checkin-announcement-renderer/checkin-announcement-renderer.component';
import { AccessControlWhiteListUsersComponent } from './components/access-control-white-list-users/access-control-white-list-users.component';
import { AccessControlInductionModePipe } from './pipes/access-control-induction-mode.pipe';
import { AccessControlAutomaticAttendanceModePipe } from './pipes/access-control-automatic-attendance-mode.pipe';
import { SupplierFormsComponent } from './components/supplier-forms/supplier-forms.component';
import { SupplierFormHistoryComponent } from './components/supplier-forms/supplier-form-history/supplier-form-history.component';
import { SupplierAnonymousFormsComponent } from './components/supplier-forms/supplier-anonymous-forms/supplier-anonymous-forms.component';
import { SupplierFillFormComponent } from './components/supplier-forms/supplier-fill-form/supplier-fill-form.component';
import { ExternalSitePermissionPipe } from './pipes/external-site-permission.pipe';
import { SupplierJoinToCompanyComponent } from './components/supplier-join-to-company/supplier-join-to-company.component';
import { SignaturePadQuestionRendererComponent } from './components/question/renderer/signature-pad-question-renderer/signature-pad-question-renderer.component';
import { QuestionSignaturePadComponent } from './components/question/question-signature-pad/question-signature-pad.component';
import { SecurityLogReportComponent } from './components/security-log-report/security-log-report.component';
import { SecurityLogTypePipe } from './pipes/security-log-type.pipe';
import { SiteAccessControlUnitManualComponent } from './components/site-access-control-unit-manual/site-access-control-unit-manual.component';
import { FormApprovalModePipe } from './pipes/form-approval-mode.pipe';
import { RegisterSiteAssetComponent } from './components/site-asset/register-site-asset/register-site-asset.component';
import { SiteAssetRequestApproveStatusPipe } from './pipes/site-asset-request-approve-status.pipe';
import { AssetCategorySelectorComponent } from './components/inventory/asset-category-selector/asset-category-selector.component';
import { ManageSiteAssetComponent } from './components/site-asset/manage-site-asset/manage-site-asset.component';
import { AssetOperatorModePipe } from './pipes/asset-operator-mode.pipe';
import { RegisterSiteAssetOperatorComponent } from './components/site-asset/register-site-asset-operator/register-site-asset-operator.component';
import { SiteAssetCheckinHistoryComponent } from './components/site-asset/site-asset-checkin-history/site-asset-checkin-history.component';
import { AssetAutoCompleteSelectorComponent } from './components/inventory/asset-auto-complete-selector/asset-auto-complete-selector.component';
import { SiteAssetQueryModePipe } from './pipes/site-asset-query-mode.pipe';
import { FilledFormEntityStatusColorPipe } from './pipes/filled-form-entity-status-color.pipe';
import { FilledFormEntityStatusIconPipe } from './pipes/filled-form-entity-status-icon.pipe';
import { SiteAssetCheckInOutComponent } from './components/site-asset/site-asset-check-in-out/site-asset-check-in-out.component';
import { TemplateCompanyAssetManagerComponent } from './components/inventory/template-company-asset-manager/template-company-asset-manager.component';
import { ListOfOperatorRegistrationModePipe } from './pipes/list-of-operator-registration-mode.pipe';
import { InductionAskModePipe } from './pipes/induction-ask-mode.pipe';
import { EnabledDisabledSwitchPipe } from './pipes/enabled-disabled-switch.pipe';
import { TemporarilyAssetIconComponent } from './custom-controls/temporarily-asset-icon/temporarily-asset-icon.component';
import { AssetSelectorComponent } from './components/inventory/asset-selector/asset-selector.component';
import { ItemSelectorComponent } from './custom-controls/item-selector/item-selector.component';
import { ManageGlobalDynamicFieldsComponent } from './components/inventory/manage-global-dynamic-fields/manage-global-dynamic-fields.component';
import { QuestionAssetFieldComponent } from './components/question/question-asset-field/question-asset-field.component';
import { MessageHistoryComponent } from './components/message-history/message-history.component';
import { NotificationSettingComponent } from './components/notification-setting/notification-setting.component';
import { NotificationCenterComponent } from './components/notification-center/notification-center.component';
import { PermissionContainerComponent } from './components/permission-container/permission-container.component';
import { ProcoreFolderStructureModePipe } from './pipes/procore-folder-structure-mode.pipe';
import { SupplierDocumentReviewModePipe } from './pipes/supplier-document-review-mode.pipe';
import { EvacuationReportComponent } from './components/evacuation-report/evacuation-report.component';
import { EvacuationModePipe } from './pipes/evacuation-mode.pipe';
import { EvacuationStatusPipe } from './pipes/evacuation-status.pipe';
import { EvacuationDetailsComponent } from './components/evacuation-report/evacuation-details/evacuation-details.component';
import { SiteSupplierDocumentVersionCommentsComponent } from './components/supplier-documents/site-supplier-document-version-comments/site-supplier-document-version-comments.component';
import { UserEditPhoneComponent } from './components/user-edit-phone/user-edit-phone.component';
import { UserInfoModalComponent } from './components/user-info-modal/user-info-modal.component';
import { FilledFormEditComponent } from './components/form-manage/filled-form-edit/filled-form-edit.component';
import { FormHistoryComponent } from './components/form-manage/form-history/form-history.component';
import { AssetOperatorModeSelectorComponent } from './components/site-asset/asset-operator-mode-selector/asset-operator-mode-selector.component';
import { SiteSelectorWithExternalComponent } from './components/site-selector-withexternal/site-selector-withexternal.component';
import { MultipleDragDropSortableComponent } from './components/items-mixture/multiple-drag-drop-sortable/multiple-drag-drop-sortable.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DocumentTypeSelectorComponent } from './custom-controls/document-type/document-type-selector.component';
import { PreviewUpsertComponent } from './components/preview-upsert/preview-upsert.component';
import { BaseGridComponent } from './custom-controls/grid/base-grid.component';
import { SafePipe } from './pipes/safe-pipe';
import { OwlDateTimeComponent } from './custom-controls/owl-date-time/owl-date-time.component';
import { GridTemplateNameDirective } from './directive/grid-template-name.directive';
import { GridTemplateComponent } from './custom-controls/grid/grid-template/grid-template.component';
import { FilterAssetCategoryComponent } from './custom-controls/filter-asset-category/filter-asset-category.component';
import { AssetCategoryTypePipe } from './pipes/asset-category-type-pipe';
import { DynamicFieldsFilterComponent } from './components/inventory/dynamic-fields-filter/dynamic-fields-filter.component';
import { DynamicFieldsFilterRendererComponent } from './components/inventory/dynamic-fields-filter-renderer/dynamic-fields-filter-renderer.component';
import { AnnouncementFilterComponent } from './custom-controls/announcement-filter/announcement-filter.component';
import { SupplierDocumentReportComponent } from './components/supplier-documents/supplier-document-report/supplier-document-report.component';
import { SiteSupplierDocumentVersionStatusPipe } from './pipes/site-supplier-document-version-status.pipe';
import { PermissionsSettingsComponent } from './components/company-settings/permissions-settings/permissions-settings.component';
import { PermissionModePipe } from './pipes/permission-mode.pipe';
import { RoleSelectorComponent } from './components/role-selector/role-selector.component';
import { HandshqIntegrationComponent } from './components/company-integrations/handshq-integration/handshq-integration.component';
import { HandsHQSupplierDocumentApprovalModePipe } from './pipes/handshq-supplier-document-approval-mode.pipe';
import { EmergencyContactComponent } from './components/required-field/emergency-contact/emergency-contact.component';
import { EmergencyContactViewerComponent } from './components/emergency-contact-viewer/emergency-contact-viewer.component';
import { QuestionAnswerFileFormatPipe } from './pipes/question-answer-file-format.pipe';
import { FileTypePipe } from './pipes/file-type.pipe';
import { DocumentTypeViewerComponent } from './components/document-type-viewer/document-type-viewer.component';
import { DocumentTypeViewerItemComponent } from './components/document-type-viewer/document-type-viewer-item/document-type-viewer-item.component';
import { DocumentTypeViewerUlComponent } from './components/document-type-viewer/document-type-viewer-ul/document-type-viewer-ul.component';
import { DocumentTypeNestedSelectorComponent } from './components/document-type-nested-selector/document-type-nested-selector.component';
import { ProcoreSyncMonitoringComponent } from './components/procore-sync-monitoring/procore-sync-monitoring.component';
import { DropzoneFileComponent } from './components/question/renderer/dropzone-file/dropzone-file.component';
import { ContactInformationComponent } from './components/site-management/contact-information/contact-information.component';
import { AccessControlUnitProviderPipe } from './pipes/access-control-unit-provider.pipe';
import { BiostarConfigurationComponent } from './components/company-integrations/biostar-integration/biostar-configuration/biostar-configuration.component';
import { ManageGatesComponent } from './components/god/manage-gates/manage-gates.component';
import { QuestionProfileComponent } from './components/question/question-profile/question-profile.component';
import { QuestionProfileSubTypePipe } from './pipes/question-profile-sub-type.pipe';
import { OffsiteSupplierCollectionModePipe } from './pipes/offsite-supplier-collection-mode.pipe';
import { TemplateCompanyVisitorTypesComponent } from './components/template-company-visitor-types/template-company-visitor-types.component';
import { AnnouncementForceViewModeSelectorComponent } from './components/site/announcement-force-view-mode-selector/announcement-force-view-mode-selector.component';
import { EmergencyContactQuestionRendererComponent } from './components/question/renderer/emergency-contact-question-renderer/emergency-contact-question-renderer.component';
import { environment } from '../environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { TokenizePipe } from './pipes/tokenize.pipe';
import { TwoFactorVerificationModePipe } from './pipes/two-factor-verification-mode.pipe';
import { PermitStartDatePolicyPipe } from './pipes/permit-start-date-policy.pipe';
import { CompanySelectorComponent } from './components/company-selector/company-selector.component';
import { ProcoreForceToSyncComponent } from './components/god/procore-force-to-sync/procore-force-to-sync.component';
import { SpecificCompanySiteSelectorComponent } from './components/site-selector/company-site-selector/specific-company-site-selector.component';
import { ProcoreIntegrationTypePipe } from './pipes/procore-integration-type.pipe';
import { IntegrationOperationPipe } from './pipes/integration-operation.pipe';
import { PermitClosurePolicyPipe } from './pipes/permit-closure-policy.pipe';
import { AccessControlUnitDirectionPipe } from './pipes/access-control-unit-direction.pipe';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SupplierDocumentReviewPageComponent } from './components/supplier-documents/supplier-document-review-page/supplier-document-review-page.component';
import { IframeAutoHeightDirective } from './directive/iframe-auto-height.directive';
import { WorkflowEngineComponent } from './workflow/workflow-engine/workflow-engine.component';
import { ComponentLoaderDirective } from './workflow/ComponentLoaderDirective';
import { WorkflowCartableComponent } from './workflow/workflow-cartable/workflow-cartable.component';
import { SupplierDocumentReviewWorkflowComponent } from './components/supplier-documents/supplier-document-review/workflow/supplier-document-review-workflow/supplier-document-review-workflow.component';
import { SupplierDocumentDetailWorkflowComponent } from './components/supplier-documents/supplier-document-review/workflow/supplier-document-detail-workflow/supplier-document-detail-workflow.component';
import { WorkflowInstanceHistoryComponent } from './workflow/workflow-instance-history/workflow-instance-history.component';
import { OtherSiteSupplierDocumentReportComponent } from './components/other-site-supplier-document-report/other-site-supplier-document-report.component';
import { SupplierDocumentKindUploderComponent } from './components/supplier-documents/supplier-document-kind-uploder/supplier-document-kind-uploder.component';
import { WorkflowFormRendererComponent } from './workflow/workflow-form-renderer/workflow-form-renderer.component';
import { CompanyWorkflowTemplateComponent } from './components/god/company-workflow-template/company-workflow-template.component';
import { WorkflowTypePipe } from './pipes/workflow-type.pipe';
import { UpsertFormComponent } from './components/form-manage/upsert-form/upsert-form.component';
import { MessageTypePipe } from './pipes/message-type-filter.pipe';
import { ScheduledReportStatusPipe } from './pipes/scheduled-report-log-status.pipe';
import { CssSpinnerComponent } from './custom-controls/css-spinner/css-spinner.component';
import { ScheduleReportModePipe } from './pipes/schedule-report-mode.pipe';
import { ImportSourcePipe } from './pipes/import-source.pipe';

export const WORKFLOW_COMPONENT: { [key: string]: Provider } = {};
WORKFLOW_COMPONENT['SupplierDocumentReviewWorkflowComponent'] =
  SupplierDocumentReviewWorkflowComponent;
WORKFLOW_COMPONENT['SupplierDocumentDetailWorkflowComponent'] =
  SupplierDocumentDetailWorkflowComponent;
import { QuestionItemsRendererComponent } from './components/question/question-items-renderer/question-items-renderer.component';
import { DisplayStylePipe } from './pipes/display-style.pipe';
import { DisplayStyleCssClassPipe } from './pipes/display-style-css-class.pipe';
import { SiteBriefingReportComponent } from './components/form-manage/site-briefing-report/site-briefing-report.component';
import { SiteBriefingStatusPipe } from './pipes/site-briefing-status.pipe';
import { ExportCsvPopupComponent } from './custom-controls/export-csv-popup/export-csv-popup.component';
import { SupplierDocumentExpiryNotificationPeriodTypePipe } from './pipes/supplier-document-expiry-notification-period-type.pipe';
import { TextRenderTypePipe } from './pipes/text-render-type.pipe';
import { CompanyFeatureConfigurationComponent } from './components/company-Feature-Configuration/company-Feature-Configuration.component';
import { RichTextRendererComponent } from './components/question/renderer/rich-text-renderer/rich-text-renderer.component';
import { ExpiryModePipe } from './pipes/expiry-mode.pipe';
import { ExpiryUnitPipe } from './pipes/expiry-unit.pipe';
import { DocumentKindNamePipe } from './pipes/document-kind.pipe';
import { DocumentKindManagerComponent } from './components/god/document-kind-manager/document-kind-manager.component';
import { SupplierDocumentAccessModePipe } from './pipes/supplier-document-access-mode.pipe';
import { SupplierDocumentExpiryDateModePipe } from './pipes/supplier-document-expiry-date-mode.pipe';
import { SupplierDocumentSignOffModePipe } from './pipes/supplier-document-sign-off-mode.pipe';
import { SupplierDocumentCommentModePipe } from './pipes/supplier-document-comment-mode.pipe';
import { SupplierDocumentAmendmentModePipe } from './pipes/supplier-document-amendment-mode.pipe';
import { WorkflowRendererComponent } from './components/workflow-renderer/workflow-renderer.component';
import { DocumentKindAddEditComponent } from './components/document-kind-add-edit/document-kind-add-edit.component';
import { RequestRevisionComponent } from './components/pending-approval/request-revision/request-revision.component';
import { WorkflowVariablesRendererComponent } from './components/workflow-renderer/workflow-variables-renderer/workflow-variables-renderer.component';
import { WorkerProfileComponent } from './components/worker-profile/worker-profile-component/worker-profile.component';
import { SiteActivityReportComponent } from './components/site-activity/site-activity-report-component/site-activity-report-component/site-activity-report.component';
import { SupplierDocumentSignoffReportComponent } from './components/supplier-documents/supplier-document-signoff/supplier-document-signoff-report/supplier-document-signoff-report.component';
import { SiteBriefingSignoffComponent } from './components/site-briefings/site-briefing-signoff/site-briefing-signoff/site-briefing-signoff.component';
import { WorkerProfileCardReportComponent } from './components/worker-profile/worker-profile-cards/worker-profile-card-report/worker-profile-card-report.component';
import { WorkerProfileHistoryComponent } from './components/worker-profile/worker-profile-history/worker-profile-history/worker-profile-history.component';
import { WorkerProfileSubTypePipe } from './pipes/worker-profile-sub-type.pipe';
import { UserDirectoryComponent } from './components/worker-profile/user-directory/user-directory.component';
import { SiteBriefingFormViewerComponent } from './components/form-manage/site-briefing-form-viewer/site-briefing-form-viewer.component';
import { WorkerProfileCardHistoryComponent } from './components/worker-profile/worker-profile-cards/worker-profile-card-history/worker-profile-card-history.component';
import { ObjectToListKeyValuePipe } from './pipes/object-to-list-key-value.pipe';
import { WorkerProfileLogTypePipe } from './pipes/worker-profile-log-type.pipe';
import { NgxPendoModule } from 'ngx-pendo';
import { DocumentExpiryStatusDirective } from './directive/document-expiry-status.directive';
import { AccessControlSiteBriefingModePipe } from './pipes/access-control-sitebriefing-mode.pipe';
import { AccessControlSupplierDocumentModePipe } from './pipes/access-control-supplier-document-mode.pipe';
import { DropdownButtonComponent } from './custom-controls/dropdown-button/dropdown-button.component';
import { EvacuationSettingEditorComponent } from './components/evacuation-setting-editor/evacuation-setting-editor.component';
import { RadioFormGroupComponent } from './custom-controls/radio-form-group/radio-form-group.component';
import { WorkProfileTabComponent } from './components/worker-profile/work-profile-tab/work-profile-tab.component';
import { UserInformationComponent } from './components/question-answer/user-information/user-information.component';
import { UserDetailCardComponent } from './components/user-detail-card/user-detail-card.component';
import { ProcoreCommentModePipe } from './pipes/procore-comment-mode.pipe';

@NgModule({
  declarations: [
    SupplierDocumentReviewWorkflowComponent,
    SupplierDocumentDetailWorkflowComponent,
    AppComponent,
    BaseGridComponent,
    SecureComponent,
    PublicComponent,
    SideBarComponent,
    TopBarComponent,
    SwitchComponent,
    RadioGroupComponent,
    DashboardComponent,
    CompanyComponent,
    SiteComponent,
    UserComponent,
    SignInComponent,
    LoginComponent,
    ModalComponent,
    ManageSiteAttendancePermissionComponent,
    DropdownButtonComponent,
    ImportSiteAttendancePermissionComponent,
    SiteAttendancePermissionComponent,
    QuestinRenderModePipe,
    QuestionBooleanComponent,
    QuestionTextComponent,
    QuestionMultiSelectComponent,
    QuestionSingleSelectComponent,
    QuestionTypePipe,
    QuestionAddComponent,
    QuestionManagerComponent,
    RadioFormGroupComponent,
    SiteQuestionsManageComponent,
    SiteQuestionsComponent,
    QuestionManagerComponent,
    QuestionAddComponent,
    EnumToArrayPipe,
    QuestionTypePipe,
    QuestionSingleSelectComponent,
    QuestionMultiSelectComponent,
    QuestionTextComponent,
    QuestionBooleanComponent,
    QuestinRenderModePipe,
    QuestionExtendedTitleComponent,
    AnonymousComponent,
    AnonymousCheckComponent,
    AnonymousCheckinComponent,
    SuccessfullAnonymousCheckinComponent,
    LoaderComponent,
    QuestionAnswerComponent,
    SiteActivityComponent,
    SiteAttendanceComponent,
    MapComponent,
    UserActivityComponent,
    UserDocumentTypesComponent,
    GodComponent,
    ManageCompaniesComponent,
    ManagePlansComponent,
    ManageIndustryTypesComponent,
    UserAutocompleteComponent,
    DatetimePipe,
    QuestionFileComponent,
    SiteAttendanceBlockedUserComponent,
    AccompanierModePipe,
    AutomaticCheckinModePipe,
    TooltipComponent,
    ManageVideosComponent,
    VideoPlayListButtonComponent,
    LightBoxComponent,
    VideoListComponent,
    QuestionRendererComponent,
    BooleanQuestionRendererComponent,
    SingleSelectQuestionRendererComponent,
    MultiSelectQuestionRendererComponent,
    TextQuestionRendererComponent,
    FileQuestionRendererComponent,
    RequiredDocumentRendererComponent,
    ManualCheckComponent,
    ManualCheckinComponent,
    ManualCheckoutComponent,
    QuestionTriggersComponent,
    NotFoundPageComponent,
    ObjectToArrayPipe,
    DescriptorComponent,
    CompanyUserComponent,
    MobilePipe,
    ChartComponent,
    WeekdayPipe,
    FlaggedUserComponent,
    CompanyOwnerComponent,
    BillingComponent,
    UserProfileComponent,
    ChangePasswordComponent,
    RegexPipe,
    CompanySettingsComponent,
    CompanyTriggerActionComponent,
    EmailTemplateComponent,
    CompanyProfileComponent,
    SiteAutomationsComponent,
    FilterDataListComponent,
    AskQuestionPeriodModePipe,
    ItemValuePipe,
    FilterPipe,
    ChipContainerComponent,
    EmergencyAlarmComponent,
    DraggableDirective,
    ResizableDirective,
    CardDesignerComponent,
    DraggableElementPageComponent,
    AccompanyPersonComponent,
    LayoutPageComponent,
    QuestionDefaultModePipe,
    ManageScannablesComponent,
    ScannableGroupsComponent,
    ScannableItemsComponent,
    FileUploadComponent,
    SiteScannableGroupComponent,
    AnnouncementManagerComponent,
    AnnouncementComponent,
    SiteAnnouncementComponent,
    CreateAnnouncementComponent,
    SiteAnnouncementManageComponent,
    OrderByPipe,
    UserContactInformationComponent,
    RequiredFieldRendererComponent,
    GenderPipe,
    DatePipe,
    SummaryWidgetComponent,
    MultiImageModePipe,
    FilterByPropertyPipe,
    YesNoPipe,
    SortPipe,
    ManageVisitorTypeComponent,
    ManageSiteVisitorTypeComponent,
    ManageSiteQuestionVisitorTypeComponent,
    CompanyCategoryPipe,
    AddRemoveQuestionAllSitesComponent,
    ImageCropperFormControlComponent,
    SiteLocationVerifyComponent,
    GroupByPipe,
    ImportSitesComponent,
    DurationSelectorComponent,
    ArrayFormControlComponent,
    NicyNullPipe,
    HourMinuteSelectorComponent,
    GroupByPipe,
    ImportSitesComponent,
    CheckListFormControlComponent,
    MyScheduledReportsComponent,
    ReportDeliveryTypePipe,
    ReportPeriodTypePipe,
    NumberToWeekdayPipe,
    UserBusinessComponent,
    FilterSiteByPermissionPipe,
    SiteUserComponent,
    NoAccessComponent,
    LandingPageComponent,
    AnonymousCheckoutComponent,
    EmailListDirective,
    SubscriptionTypePipePipe,
    ConditionalQuestionComponent,
    AnswerSelectorComponent,
    QuickSetupComponent,
    AdditionalCompanyHostComponent,
    MinimalAddSiteComponent,
    ToFormGroupPipe,
    AddressFormControlComponent,
    DicToArrayPipe,
    ToAnyPipe,
    AttendanceFieldRendererComponent,
    AttendanceFieldComponent,
    HostPushNotificationComponent,
    FilteredAnnouncementComponent,
    CompanyEmployeeComponent,
    DocumentReportComponent,
    DocumentExpiryStatusPipe,
    ProcoreIntegrationComponent,
    PaginationComponent,
    SiteSelectorComponent,
    AssetSelectorComponent,
    CompanySiteSelectorComponent,
    PopoverFilterSimpleComponent,
    FilterHeaderComponent,
    SortableTitleHeaderComponent,
    FilterListRendererComponent,
    AnnouncementModePipe,
    TableSettingComponent,
    DurationFormControlComponent,
    DateTimePickerComponent,
    InputDateTimePickerComponent,
    AskModePipe,
    CompanySiteScopePipe,
    TextOperatorPipe,
    CompanySupplierComponent,
    EditCompanySupplierComponent,
    CompanySupplierSelectorComponent,
    DocumentTypeSelectorComponent,
    InductionModePipe,
    InductionStatusComponent,
    DataRetentionPolicyComponent,
    SiteInductionManageComponent,
    InductionManageComponent,
    SiteInductionComponent,
    InductionRendererComponent,
    UserCardComponent,
    AnnouncementRendererComponent,
    AnnouncementItemRendererComponent,
    UpsertInductionComponent,
    QuestionSelectorComponent,
    DocumentSelectorComponent,
    AnnouncementSelectorComponent,
    InductionSelectorComponent,
    FormInjectorComponent,
    UserSelectorComponent,
    RequiredDocumentListRendererComponent,
    VisitorTypeSelectorComponent,
    DisputeAttendanceComponent,
    MarkdownEditorComponent,
    QuestionTitleRendererComponent,
    DisputeHistoryComponent,
    SortableComponent,
    ManageInventoryComponent,
    ManageInventoryLocationsComponent,
    ManageToolCategoriesComponent,
    ManageToolStatusesComponent,
    InventoryToolReportComponent,
    InventoryLocationSelectorComponent,
    ToolAssignHistoryComponent,
    ToolImagesComponent,
    ToolDynamicFieldTypePipe,
    UpsertInventoryAssetComponent,
    ToolFieldRendererComponent,
    TextToolFieldRendererComponent,
    ImageToolFieldRendererComponent,
    FormManageComponent,
    UpsertFormComponent,
    SiteFormsComponent,
    FormReportComponent,
    InventoryStatusTriggerEditorComponent,
    InventoryStatusTriggersComponent,
    ScheduleReportTypePipe,
    InventoryDynamicFieldTriggersComponent,
    InventoryDynamicFieldTriggerEditorComponent,
    InductionReportComponent,
    SiteInductionStatusPipe,
    InductionFilledInfoComponent,
    SiteInductionDetailComponent,
    SimpleDocumentRendererComponent,
    FormTitlePipe,
    SupplierDocumentsComponent,
    ManageSupplierDocumentTypeComponent,
    ManageSupplierDocumentsComponent,
    SupplierDocumentSelectorComponent,
    SupplierDocumentSignOffViewerComponent,
    SiteDocumentStatusPipe,
    ViewSiteSupplierDocumentVersionComponent,
    SiteSupplierDocumentUploaderComponent,
    SupplierDocumentRendererComponent,
    SiteSupplierDocumentUploaderContainerComponent,
    SiteAttendanceApprovalStatusComponent,
    ClipboardBoxComponent,
    SiteAttendanceApprovalStatusPipe,
    SupplierDocumentTypeStatusIconPipe,
    SupplierDocumentTypeStatusTooltipPipe,
    ViewSiteSupplierDocumentVersionHistoryComponent,
    FileViewerComponent,
    ManageRegionComponent,
    RegionSelectorComponent,
    ViewSiteSupplierDocumentVersionHistoryComponent,
    OrderByFuctionPipe,
    MyobIntegrationComponent,
    MyobConfigurationAccessCodeCallbackComponent,
    ProcoreConfigurationAccessCodeCallbackComponent,
    MyobPeriodTypePipe,
    FormSelectorComponent,
    FormDataDetailsComponent,
    TriggerSelectorComponent,
    FormScopePipe,
    SupplierDocumentReviewComponent,
    FormDataRendererComponent,
    FormDetailsComponent,
    AnnouncementDisplayModePipe,
    ItemsMixtureComponent,
    ItemsRendererComponent,
    SingleQuestionRendererComponent,
    SiteManagementComponent,
    SiteQuestionsManagementComponent,
    AccordionHeaderComponent,
    CompanyIntegrationsComponent,
    CompanyInductionSelectorComponent,
    FormAskModePipe,
    SiteSupplierDocumentMultipleUploaderComponent,
    SupplierDocumentReminderComponent,
    PendingToApproveListComponent,
    ApproveEntityTypePipe,
    SupplierDocumentDescriptionViewerComponent,
    HtmlNewLinePipe,
    CompanyDocumentTypeComponent,
    OptionalMandatorySelectorComponent,
    OptionalMandatoryPipe,
    ProcoreExportSiteAttendanceAsTimeCardModePipePipe,
    ProcoreExportSiteAttendanceAsVisitorLogModePipePipe,
    ProcoreExportSiteAttendanceAsManpowerModePipe,
    FormTypePipe,
    FormApplyForPipe,
    AnonymousPermitComponent,
    FilledFormStatusPipe,
    FormRegisterForPipe,
    FormDataStatusPipe,
    AutoCheckOutTypePipe,
    PermitStatusIconPipe,
    PermitStatusTitlePipe,
    PermitStatusColorPipe,
    DelayAnswerModePipe,
    QuestionDateComponent,
    DateQuestionLimitComponent,
    DateQuestionRendererComponent,
    DocumentAnswerRendererComponent,
    QuestionListAnswerRendererComponent,
    SupplierDocumentApprovalModePipe,
    FilterByConditionPipe,
    PreCheckInListComponent,
    SignaturePadComponent,
    ApproveStatusPipe,
    ApproveRejectComponent,
    PermitOpenPolicyPipe,
    PermitStatusPipe,
    PermitReportComponent,
    DaysOfWeekPipe,
    PermitScheduleSelectorComponent,
    PermitScheduleItemComponent,
    UpdatePermitStatusComponent,
    PermitHistoryComponent,
    TimePipe,
    QRCodeCheckinModePipe,
    CheckinLocationModePipe,
    SiteAnnouncementForceViewModePipe,
    QuestionAnswerFilterComponent,
    QuestionAnswerFilterRendererComponent,
    SiteAnnouncementDisplayTimePipe,
    TwoFactorVerificationModePipe,
    CheckinAnnouncementRendererComponent,
    AccessControlWhiteListUsersComponent,
    AccessControlInductionModePipe,
    AccessControlSiteBriefingModePipe,
    AccessControlSupplierDocumentModePipe,
    AccessControlAutomaticAttendanceModePipe,
    SupplierFormsComponent,
    SupplierFormHistoryComponent,
    SupplierAnonymousFormsComponent,
    SupplierFillFormComponent,
    ExternalSitePermissionPipe,
    SupplierJoinToCompanyComponent,
    SignaturePadQuestionRendererComponent,
    QuestionSignaturePadComponent,
    SecurityLogReportComponent,
    SecurityLogTypePipe,
    SiteAccessControlUnitManualComponent,
    FormApprovalModePipe,
    RegisterSiteAssetComponent,
    SiteAssetRequestApproveStatusPipe,
    AssetCategorySelectorComponent,
    ManageSiteAssetComponent,
    AssetOperatorModePipe,
    RegisterSiteAssetOperatorComponent,
    SiteAssetCheckinHistoryComponent,
    AssetAutoCompleteSelectorComponent,
    SiteAssetQueryModePipe,
    AssetCategoryTypePipe,
    FilledFormEntityStatusColorPipe,
    FilledFormEntityStatusIconPipe,
    SiteAssetCheckInOutComponent,
    TemplateCompanyAssetManagerComponent,
    ListOfOperatorRegistrationModePipe,
    InductionAskModePipe,
    EnabledDisabledSwitchPipe,
    TemporarilyAssetIconComponent,
    ItemSelectorComponent,
    ManageGlobalDynamicFieldsComponent,
    QuestionAssetFieldComponent,
    MessageHistoryComponent,
    NotificationSettingComponent,
    NotificationCenterComponent,
    PermissionContainerComponent,
    ProcoreFolderStructureModePipe,
    SupplierDocumentReviewModePipe,
    EvacuationReportComponent,
    EvacuationModePipe,
    EvacuationStatusPipe,
    EvacuationDetailsComponent,
    SiteSupplierDocumentVersionCommentsComponent,
    FilledFormEditComponent,
    FormHistoryComponent,
    AssetOperatorModeSelectorComponent,
    UserEditPhoneComponent,
    UserInfoModalComponent,
    SiteSelectorWithExternalComponent,
    MultipleDragDropSortableComponent,
    PreviewUpsertComponent,
    OwlDateTimeComponent,
    SafePipe,
    FilterAssetCategoryComponent,
    GridTemplateNameDirective,
    GridTemplateComponent,
    DynamicFieldsFilterComponent,
    DynamicFieldsFilterRendererComponent,
    AnnouncementFilterComponent,
    SupplierDocumentReportComponent,
    SiteSupplierDocumentVersionStatusPipe,
    PermissionsSettingsComponent,
    PermissionModePipe,
    RoleSelectorComponent,
    QuestionAnswerFileFormatPipe,
    FileTypePipe,
    DocumentTypeViewerComponent,
    DocumentTypeViewerItemComponent,
    DocumentTypeViewerUlComponent,
    DocumentTypeNestedSelectorComponent,
    HandshqIntegrationComponent,
    HandsHQSupplierDocumentApprovalModePipe,
    EmergencyContactComponent,
    EmergencyContactViewerComponent,
    QuestionProfileComponent,
    QuestionProfileSubTypePipe,
    DropzoneFileComponent,
    ProcoreSyncMonitoringComponent,
    ContactInformationComponent,
    AccessControlUnitProviderPipe,
    BiostarConfigurationComponent,
    ContactInformationComponent,
    AnnouncementForceViewModeSelectorComponent,
    OffsiteSupplierCollectionModePipe,
    ManageGatesComponent,
    TemplateCompanyVisitorTypesComponent,
    EmergencyContactQuestionRendererComponent,
    TokenizePipe,
    PermitStartDatePolicyPipe,
    ProcoreForceToSyncComponent,
    CompanySelectorComponent,
    SpecificCompanySiteSelectorComponent,
    ProcoreIntegrationTypePipe,
    IntegrationOperationPipe,
    PermitClosurePolicyPipe,
    AccessControlUnitDirectionPipe,
    PdfViewerComponent,
    SupplierDocumentReviewPageComponent,
    IframeAutoHeightDirective,
    QuestionItemsRendererComponent,
    DisplayStylePipe,
    DisplayStyleCssClassPipe,
    WorkflowEngineComponent,
    ComponentLoaderDirective,
    WorkflowCartableComponent,
    DocumentKindNamePipe,
    WorkflowInstanceHistoryComponent,
    OtherSiteSupplierDocumentReportComponent,
    SupplierDocumentKindUploderComponent,
    WorkflowFormRendererComponent,
    CompanyWorkflowTemplateComponent,
    WorkflowTypePipe,
    MessageTypePipe,
    SiteBriefingReportComponent,
    SiteBriefingStatusPipe,
    ScheduledReportStatusPipe,
    CssSpinnerComponent,
    ScheduleReportModePipe,
    ExportCsvPopupComponent,
    SupplierDocumentExpiryNotificationPeriodTypePipe,
    TextRenderTypePipe,
    RichTextRendererComponent,
    CompanyFeatureConfigurationComponent,
    CompanyFeatureConfigurationComponent,
    ExpiryModePipe,
    ExpiryUnitPipe,
    DocumentKindManagerComponent,
    SupplierDocumentAccessModePipe,
    SupplierDocumentExpiryDateModePipe,
    SupplierDocumentSignOffModePipe,
    SupplierDocumentCommentModePipe,
    SupplierDocumentAmendmentModePipe,
    WorkflowRendererComponent,
    DocumentKindAddEditComponent,
    RequestRevisionComponent,
    WorkflowVariablesRendererComponent,
    WorkerProfileComponent,
    SiteActivityReportComponent,
    InductionReportComponent,
    SupplierDocumentSignoffReportComponent,
    SiteBriefingSignoffComponent,
    WorkerProfileCardReportComponent,
    WorkerProfileHistoryComponent,
    WorkerProfileSubTypePipe,
    UserDirectoryComponent,
    SiteBriefingFormViewerComponent,
    WorkerProfileCardHistoryComponent,
    ObjectToListKeyValuePipe,
    WorkerProfileLogTypePipe,
    DocumentExpiryStatusDirective,
    ImportSourcePipe,
    EvacuationSettingEditorComponent,
    RadioFormGroupComponent,
    WorkProfileTabComponent,
    UserInformationComponent,
    UserDetailCardComponent,
    ProcoreCommentModePipe,
  ],
  entryComponents: [
    UpsertInductionComponent,
    QuestionSelectorComponent,
    DocumentSelectorComponent,
    AnnouncementSelectorComponent,
    InductionSelectorComponent,
    FormInjectorComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    ModalModule.forRoot(),
    NgxBootstrapModule,
    GoogleMapsModule,
    ChartModule,
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    SortableModule.forRoot(),
    ImageCropperModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    NgxFileDropModule,
    DragDropModule,
    //SortablejsModule.forRoot({ animation: 250 }),
    RecaptchaV3Module,
    NgxExtendedPdfViewerModule,
    NgxPendoModule.forRoot({
      pendoApiKey: environment.pendoApiKey,
      pendoIdFormatter: (pendoId: string) => pendoId.toLowerCase(),
    }),
  ],
  exports: [NgxBootstrapModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHelperInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'en-AU' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
    SignInService,
    DraggableItemService,
    CanDeactivateGuard,
    DatetimePipe,
    DatePipe,
    WeekdayPipe,
    OrderByPipe,
    GenderPipe,
    MobilePipe,
    FormTypePipe,
    PermitStatusPipe,
    SiteInductionStatusPipe,
    TextOperatorPipe,
    EnumToArrayPipe,
    ApproveEntityTypePipe,
    AccessControlAutomaticAttendanceModePipe,
    AccessControlUnitProviderPipe,
    HtmlNewLinePipe,
    DocumentKindNamePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}
